import React from 'react';
import { SpecialFusion } from './SpecialFusion';
import { SpecialFusionM3 } from './SpecialFusionForM3';
import { useHistory } from 'react-router-dom';
import { Devil, initialDevil } from '../../ducks/model';

const SpecialFusionWrapperMain = (props) => {

  const { series } = props;
  const history = useHistory();
  const check: boolean =
    history.location && history.location.state && history.location.state.choice;
  const devil: Devil = check ? history.location.state.choice : initialDevil

  return (
    <>
      {series === "m_dsj"
        ? <SpecialFusion series={series} />
        : <SpecialFusionM3 series={series} devil={devil} />
      }
    </>
  );
}

export const SpecialFusionWrapper = React.memo(SpecialFusionWrapperMain);
