import React from 'react';
import './SpecialFusionResultForM3.scss';
import DevilContent from '../DevilContent/DevilContent';
import { SpecialResultDevilSetForM3 } from '../../ducks/model';

function SpecialFusionResultForM3(
  props: SpecialResultDevilSetForM3 & { series: string }
) {

  return (
    <div className="devil-fusion">
      <div className={"fusion-content"}>
        <DevilContent
          devil={props.devil1}
          series={props.series}
          color={"devil1"}
        ></DevilContent>
      </div>
      <div className={"fusion-icon add "}>＋</div>
      <div className={"fusion-content"}>
        <DevilContent
          devil={props.devil2}
          series={props.series}
          color={"devil2"}
        ></DevilContent>
      </div>
      {props.sacrifice
        ? <div className={"fusion-icon add "}>＋</div>
        : null
      }
      {props.sacrifice
        ? <div className={"fusion-content"}>
          <DevilContent
            devil={props.sacrifice}
            series={props.series}
            color={"sacrifice"}
          ></DevilContent>
        </div>
        : null
      }
    </div>
  );

}

export default SpecialFusionResultForM3;
