import { Devil, ResultDevilSet } from './model';

export const ActionType = {
  RACE1_CHANGE: 'RACE1_CHANGE',
  DEVIL1_CHANGE: 'DEVIL1_CHANGE',
  RACE2_CHANGE: 'RACE2_CHANGE',
  DEVIL2_CHANGE: 'DEVIL2_CHANGE',
  MAJIN_CHECK_CHANGE: 'MAJIN_CHECK_CHANGE',
  CURSE_CHECK_CHANGE: 'CURSE_CHECK_CHANGE',
  DEVIL_LIST_RESET: 'DEVIL_LIST_RESET',
  NORMAL_RESULT_SET: 'NORMAL_RESULT_SET',
  REVERSE_RESULT_SET: 'REVERSE_RESULT_SET',
  RESULT_CLEAR: 'RESULT_CLEAR',
  DEVIL_CHOICE: 'DEVIL_CHOICE',
  CHOICE_CLEAR: 'CHOICE_CLEAR',
  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',
  SELECTED_DEVIL: 'SELECTED_DEVIL',
  SELECTED_DATA: 'SELECTED_DATA',
  SELECTED_TURN: 'SELECTED_TURN',
  ACCORDION_DEVIL: 'ACCORDION_DEVIL',
}

export function onChangeRaceList1(race: string, series: string) {
  return { type: ActionType.RACE1_CHANGE, race1: race, series: series };
};

export function onChangeDevilList1(race: string, series: string) {
  return { type: ActionType.DEVIL1_CHANGE, race2: race, series: series };
};

export function onChangeRaceList2(devil: string, series: string) {
  return { type: ActionType.RACE2_CHANGE, devil1: devil, series: series };
};

export function onChangeDevilList2(devil: string, series: string) {
  return { type: ActionType.DEVIL1_CHANGE, devil2: devil, series: series };
};

export function devilListReset(series: string) {
  return { type: ActionType.DEVIL_LIST_RESET, series: series }
};

export function majinCheckChange(majin: boolean) {
  return { type: ActionType.MAJIN_CHECK_CHANGE, majin: majin };
};

export function curseCheckChange(curse: boolean) {
  return { type: ActionType.CURSE_CHECK_CHANGE, curse: curse };
};

export function normalResultSet(resultDevilSet: ResultDevilSet) {
  return {
    type: ActionType.NORMAL_RESULT_SET,
    resultDevilSet: resultDevilSet,
  };
}

export function reverseResultSet(
  resultDevilSet: ResultDevilSet
) {
  return {
    type: ActionType.REVERSE_RESULT_SET,
    resultDevilSet: resultDevilSet,
  };
}

export function resultClear(series: string) {
  return { type: ActionType.RESULT_CLEAR, series: series };
}

export function choiceDevil(devilData: Devil) {
  return { type: ActionType.DEVIL_CHOICE, devilData: devilData };
}

export function choiceClear(series: string) {
  return { type: ActionType.CHOICE_CLEAR, series: series };
}

export function openModal(b: boolean) {
  return { type: ActionType.MODAL_OPEN, b: b };
}

export function closeModal(b: boolean) {
  return { type: ActionType.MODAL_CLOSE, b: b };
}

export function selectedDevil(event: String) {
  return { type: ActionType.SELECTED_DEVIL, event: event };
}

export function selectedData(event: String) {
  return { type: ActionType.SELECTED_DATA, event: event };
}

export function selectedTurn(event: String) {
  return { type: ActionType.SELECTED_TURN, event: event };
}

export function accordionDevil(selectedId: number, openFlg: boolean) {
  return {
    type: ActionType.ACCORDION_DEVIL,
    selectedId: selectedId,
    openFlg: openFlg
  };
}

