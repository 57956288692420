import React, { useCallback, useEffect, useState } from 'react';
import { LoggingManager as logger } from '../../common/CommonLogger';
import { FilterProps } from './types';
import './Filter.scss';
import { Modal, Backdrop, Fade, makeStyles, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import { ComboBoxManager } from '../../common/ComboBoxManager';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// modal style
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '1px solid #fff',
    boxShadow: '1px 3px 5px -1px rgba(255,255,255,0.2), 0px 5px 8px 0px rgba(255,255,255,0.2), 0px 1px 14px 0px rgba(255,255,255,0.2)',
    padding: theme.spacing(2, 2),
    width: '80%',
  },
}));

// switch style
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

export const Filter = (props: FilterProps) => {
  const {
    series,
    curse,
    filterRaceList1,
    filterRaceList2,
    filterRaceList3,
    filterDevilList1,
    filterDevilList2,
    filterDevilList3,
    changeRaceList1,
    changeRaceList2,
    changeRaceList3,
    changeDevilList1,
    changeDevilList2,
    changeDevilList3,
    filterClear,
    curseChange
  } = props;

  const [race1, setRace1] = useState<string | null>(null);
  const [race2, setRace2] = useState<string | null>(null);
  const [race3, setRace3] = useState<string | null>(null);
  const [devil1, setDevil1] = useState<string | null>(null);
  const [devil2, setDevil2] = useState<string | null>(null);
  const [devil3, setDevil3] = useState<string | null>(null);
  const [curseCheck, setCurseCheck] = useState(curse);

  const onChangeRaceList1 = useCallback(
    (race: string | null) => {
      if (race) {
        logger(["onChangeRaceList1 execute"]);
        setRace1(race);
        changeRaceList1(race);
      }
    }, [changeRaceList1]
  );
  const onChangeRaceList2 = useCallback(
    (race: string | null) => {
      if (race && changeRaceList2) {
        logger(["onChangeRaceList2 execute"]);
        setRace2(race);
        changeRaceList2(race);
      }
    }, [changeRaceList2]
  );
  const onChangeRaceList3 = useCallback(
    (race: string | null) => {
      if (race && changeRaceList3) {
        logger(["onChangeRaceList3 execute"]);
        setRace3(race);
        changeRaceList3(race);
      }
    }, [changeRaceList3]
  );
  const onChangeDevilList1 = useCallback(
    (devil: string | null) => {
      if (devil) {
        logger(["onChangeDevilList1 execute"]);
        setDevil1(devil);
        changeDevilList1(devil);
      }
    }, [changeDevilList1]
  );
  const onChangeDevilList2 = useCallback(
    (devil: string | null) => {
      if (devil && changeDevilList2) {
        logger(["onChangeDevilList2 execute"]);
        setDevil2(devil);
        changeDevilList2(devil);
      }
    }, [changeDevilList2]
  );
  const onChangeDevilList3 = useCallback(
    (devil: string | null) => {
      if (devil && changeDevilList3) {
        logger(["onChangeDevilList3 execute"]);
        setDevil3(devil);
        changeDevilList3(devil);
      }
    }, [changeDevilList3]
  );

  const curseCheckChange = useCallback(() => {
    setCurseCheck(!curseCheck);
    if (curseChange) {
      curseChange(!curseCheck);
    }
  }, [curseChange, curseCheck]);

  // switch setting
  type CheckedProps = {
    checked: boolean;
  }
  const [state, setState] = useState<CheckedProps>({
    checked: true,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, checked: event.target.checked });
    // curseCheckChange();
  };

  const onFilterClear = useCallback(() => {
    setRace1(null);
    setRace2(null);
    setRace3(null);
    setDevil1(null);
    setDevil2(null);
    setDevil3(null);
    if (filterClear) {
      filterClear();
    }
  }, [filterClear]);

  useEffect(() => {
    setRace1(null);
    setRace2(null);
    setRace3(null);
    setDevil1(null);
    setDevil2(null);
    setDevil3(null);
  }, [filterClear]);

  // modal setting
  const classes = useStyles();
  const [openFlg, setOpenFlg] = useState(false);
  const handleOpen = () => { setOpenFlg(true); };
  const handleClose = () => { setOpenFlg(false); };

  return (
    <div className="filter-wrapper">
      <div className="filter">
        <IconButton className="filter-button" aria-label="delete" color="primary" onClick={handleOpen}>
          <SearchIcon></SearchIcon>
        </IconButton>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFlg}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFlg}>
          <div className={classes.paper + " filter-wrapper modal-wrapper"}>
            <h2 className="modal-title">
              絞り込み
            </h2>
            <div className="filter-option">
              {series === 'm_3' && curseChange &&
                <div className="child">
                  <FormGroup>
                    <Typography component="div">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item className="option-name">呪い合体</Grid>
                        <Grid item>
                          <AntSwitch checked={curseCheck} onChange={() => curseCheckChange()} name="checked" />
                        </Grid>
                      </Grid>
                    </Typography>
                  </FormGroup>
                </div>
              }
              <Button variant="contained" onClick={() => onFilterClear()}>条件クリア</Button>
            </div>

            <div className="filter-devil-wrapper">
              <div className={"filter-devil " + ((changeRaceList2 || changeRaceList3) ? "devil1" : "")}>
                {(changeRaceList2 || changeRaceList3) &&
                  <div className="element">
                    素材1
                  </div>
                }
                <div className="element-combo-wrapper">
                  <ComboBoxManager
                    id={"filter-combo-devil1-type"}
                    value={race1}
                    options={filterRaceList1}
                    onChange={onChangeRaceList1}
                    placeholder={"種族"}
                    addSuggestFlg={{ flg: true, series: series, pattern: "race" }}
                  ></ComboBoxManager>
                  <ComboBoxManager
                    id={"filter-combo-devil1-name"}
                    value={devil1}
                    options={filterDevilList1}
                    onChange={onChangeDevilList1}
                    placeholder={"悪魔"}
                    sortFlg={true}
                  ></ComboBoxManager>
                </div>
              </div>

              {changeRaceList2 && filterRaceList2 && onChangeDevilList2 && filterDevilList2 &&
                <div className="filter-devil devil2">
                  <div className="element">
                    素材2
                  </div>
                  <div className="element-combo-wrapper">
                    <ComboBoxManager
                      id={"filter-combo-devil2-type"}
                      value={race2}
                      options={filterRaceList2}
                      onChange={onChangeRaceList2}
                      placeholder={"種族"}
                      addSuggestFlg={{ flg: true, series: series, pattern: "race" }}
                    ></ComboBoxManager>
                    <ComboBoxManager
                      id={"filter-combo-devil2-name"}
                      value={devil2}
                      options={filterDevilList2}
                      onChange={onChangeDevilList2}
                      placeholder={"悪魔"}
                      sortFlg={true}
                    ></ComboBoxManager>
                  </div>
                </div>
              }

              {changeRaceList3 && filterRaceList3 && changeDevilList3 && filterDevilList3 &&
                <div className="filter-devil devil3">
                  <div className="element">
                    生贄
                  </div>
                  <div className="element-combo-wrapper">
                    <ComboBoxManager
                      id={"filter-combo-devil3-type"}
                      value={race3}
                      options={filterRaceList3}
                      onChange={onChangeRaceList3}
                      placeholder={"種族"}
                      addSuggestFlg={{ flg: true, series: series, pattern: "race" }}
                    ></ComboBoxManager>
                    <ComboBoxManager
                      id={"filter-combo-devil3-name"}
                      value={devil3}
                      options={filterDevilList3}
                      onChange={onChangeDevilList3}
                      placeholder={"悪魔"}
                      sortFlg={true}
                    ></ComboBoxManager>
                  </div>
                </div>
              }
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

