import React, { useCallback, useEffect, useState } from 'react';
import { FusionResult } from '../../components/FusionResult/FusionResult';
import { connect, useDispatch } from 'react-redux';
import {
  choiceDevilSelector,
  selectedDevilSelector,
  selectedDataSelector,
  selectedTurnSelector,
  curseSelector
} from '../../ducks/selector';
import { getReverseResult } from '../../common/CalcResult';
import { LoggingManager as logger } from '../../common/CommonLogger';
import {
  extractDevilsFromResult,
  extractRacesFromResult,
  filterResultForDevil,
  filterResultForRace,
} from '../../common/DataSelector';
import { ResultDevilSet } from '../../ducks/model';
import { ActionType } from '../../ducks/actions';
import { Filter } from '../../components/Filter/Filter';
import Loading from '../../components/Loading/Loading';

/**
 * ReverseFusionMain
 *   - 逆引き合体メインコンポーネント
 * 
 * @param props - mapStateProps
 */
const ReverseFusionMain = (props) => {

  // props から情報抽出
  const { choiceDevil, series, curse } = props;

  // 逆引き合体結果の算出
  const [reverseResult, setReverseResult] = useState(
    getReverseResult(choiceDevil, series, curse)
  );
  const [curseState, setCurseState] = useState(curse);
  const [filterRaceList1, setFilterRaceList1] = useState(
    extractRacesFromResult(reverseResult, "devil1", series)
  );
  const [filterRaceList2, setFilterRaceList2] = useState(
    extractRacesFromResult(reverseResult, "devil2", series)
  );
  const [filterDevilList1, setFilterDevilList1] = useState(
    extractDevilsFromResult(reverseResult, "devil1")
  );
  const [filterDevilList2, setFilterDevilList2] = useState(
    extractDevilsFromResult(reverseResult, "devil2")
  );

  const listReset = useCallback(
    (filterResultList: ResultDevilSet[]) => {
      setFilterRaceList1(extractRacesFromResult(filterResultList, "devil1", series));
      setFilterRaceList2(extractRacesFromResult(filterResultList, "devil2", series));
      setFilterDevilList1(extractDevilsFromResult(filterResultList, "devil1"));
      setFilterDevilList2(extractDevilsFromResult(filterResultList, "devil2"));
      setReverseResult(filterResultList);
    }, [series]
  );

  const changeRaceList1 = useCallback(
    (race: string) => {
      const filterResultList: ResultDevilSet[] = filterResultForRace(
        reverseResult,
        "devil1",
        race,
        series,
      );
      listReset(filterResultList);
    }, [reverseResult, series, listReset]
  );
  const changeRaceList2 = useCallback(
    (race: string) => {
      const filterResultList: ResultDevilSet[] = filterResultForRace(
        reverseResult,
        "devil2",
        race,
        series,
      );
      listReset(filterResultList);
    }, [reverseResult, series, listReset]
  );
  const changeDevilList1 = useCallback(
    (devil: string) => {
      const filterResultList: ResultDevilSet[] = filterResultForDevil(
        reverseResult,
        "devil1",
        devil,
      );
      listReset(filterResultList);
    }, [reverseResult, listReset]
  );
  const changeDevilList2 = useCallback(
    (devil: string) => {
      const filterResultList: ResultDevilSet[] = filterResultForDevil(
        reverseResult,
        "devil2",
        devil,
      );
      listReset(filterResultList);
    }, [reverseResult, listReset]
  );

  const dispatch = useDispatch();
  const curseChange = useCallback(
    (value: boolean) => {
      setCurseState(value);
      dispatch({ type: ActionType.CURSE_CHECK_CHANGE, curse: value });
    }, [setCurseState, dispatch]
  );

  const filterClear = useCallback(() => {
    const filterResultList: ResultDevilSet[] = getReverseResult(
      choiceDevil,
      series,
      curse
    );
    listReset(filterResultList);
  }, [choiceDevil, series, curse, listReset]);

  useEffect(() => {
    const filterResultList: ResultDevilSet[] = getReverseResult(
      choiceDevil,
      series,
      curse
    );
    listReset(filterResultList);
  }, [choiceDevil, series, curse, listReset]);

  logger(['ReverseFusion', reverseResult]);

  return (
    <>
      <Loading display={false}></Loading>
      <Filter
        series={series}
        curse={curseState}
        filterRaceList1={filterRaceList1}
        filterRaceList2={filterRaceList2}
        filterDevilList1={filterDevilList1}
        filterDevilList2={filterDevilList2}
        changeRaceList1={changeRaceList1}
        changeRaceList2={changeRaceList2}
        changeDevilList1={changeDevilList1}
        changeDevilList2={changeDevilList2}
        filterClear={filterClear}
        curseChange={curseChange}
      />
      <FusionResult
        resultDevilSet={reverseResult}
        displayPtn={false}
        fixColumn='resultDevil'
        series={series}
      />
    </>
  );
}

/**
 * mapStateProps
 *   - state から props を設定する
 * 
 * @param state - state
 */
function mapStateProps(state) {
  return {
    choiceDevil: choiceDevilSelector(state),
    selectedDevil: selectedDevilSelector(state),
    selectedData: selectedDataSelector(state),
    selectedTurn: selectedTurnSelector(state),
    curse: curseSelector(state),
  };
}

/**
 * ReverseFusion
 *   - memo化した ReverseFusionMain
 */
export const ReverseFusion = React.memo(
  connect(
    mapStateProps,
    null,
    null,
    {
      pure: true,
      areStatePropsEqual(next, prev) {
        logger(["ReverseFusion state compare next:", next]);
        logger(["ReverseFusion state compare prev:", prev]);

        const baseChangeCheck: boolean = (
          next.choiceDevil.basicData.name === prev.choiceDevil.basicData.name &&
          next.selectedDevil === prev.selectedDevil &&
          next.selectedData === prev.selectedData &&
          next.selectedTurn === prev.selectedTurn &&
          next.curse === prev.curse
        );

        return baseChangeCheck;
      }
    }
  )
    (ReverseFusionMain)
);
