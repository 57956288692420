import { getDevilInfoByName } from '../../common/DataSelector'
import {
  getResultList,
  getResult,
  getPattern,
  convertRaceName,
} from '../../common/CalcResult'
import {
  Devil,
  Pattern,
  ResultDevilSet,
  initialPattern,
  noResultForFusion,
  unselected
} from '../../ducks/model';


const StandardFusion = (
  devil1: Devil, devil2: Devil, series: string, curse: boolean
) => {

  const level = (devil1.basicData.level + devil2.basicData.level) / 2 + 1;

  const tmpPatternResult: Pattern = getPattern(devil1, devil2, series, curse);
  const patternResult: Pattern = tmpPatternResult.id !== 0 ? tmpPatternResult : initialPattern;
  const displayFlg = patternResult.result_type !== 'None' && patternResult !== initialPattern;

  let resultDevilSet: ResultDevilSet[] = [{
    devil1: devil1,
    devil2: devil2,
    resultDevil: noResultForFusion,
  }];

  if (displayFlg) {
    if (devil1.basicData.name === unselected) {
      resultDevilSet = getResultList(
        patternResult,
        devil1.basicData.name,
        devil2.basicData.name,
        devil2.basicData.level,
        devil1.basicData.type,
        series
      );

    } else if (devil2.basicData.name === unselected) {
      resultDevilSet = getResultList(
        patternResult,
        devil1.basicData.name,
        devil2.basicData.name,
        devil1.basicData.level,
        devil2.basicData.type,
        series
      );

    } else {
      resultDevilSet = [{
        devil1: convertRaceName(
          getDevilInfoByName(devil1.basicData.name, series),
          series
        ),
        devil2: convertRaceName(
          getDevilInfoByName(devil2.basicData.name, series),
          series
        ),
        resultDevil: convertRaceName(
          getResult(patternResult, level, series),
          series
        ),
      }];
    }
  }

  return resultDevilSet;
};

export default StandardFusion;
