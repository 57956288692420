import { createSelector } from 'reselect';
import { Devil, ResultDevilSet } from './model';

const devil1State = state => state.conditionReducer.devil1;
const devil2State = state => state.conditionReducer.devil2;
const majinState = state => state.conditionReducer.majin;
const curseState = state => state.conditionReducer.curse;

const normalResultState = state => state.resultReducer.normalResult;
const reverseResultState = state => state.resultReducer.reverseResult;

const choiceDevilState = state => state.choiceReducer.choiceDevil;
const selectedDevilState = state => state.sortReducer.selectedDevil;
const selectedDataState = state => state.sortReducer.selectedData;
const selectedTurnState = state => state.sortReducer.selectedTurn;

export const devil1Selector = createSelector(
  [devil1State], (devil1) => {
    return devil1;
  }
);

export const devil2Selector = createSelector(
  [devil2State], (devil2) => {
    return devil2;
  }
);

export const majinSelector = createSelector(
  [majinState], (majin) => {
    return majin;
  }
);

export const curseSelector = createSelector(
  [curseState], (curse) => {
    return curse;
  }
);

export const normalResultSelector = createSelector(
  [normalResultState], (normalResult) => {
    return normalResult;
  }
);

export const reverseResultSelector = createSelector(
  [reverseResultState], (reverseResult) => {
    return reverseResult;
  }
);

export const choiceDevilSelector = createSelector(
  [choiceDevilState], (choiceDevil) => {
    return choiceDevil;
  }
);

export const selectedDevilSelector = createSelector(
  [selectedDevilState], (selectedDevil) => {
    return selectedDevil;
  }
);

export const selectedDataSelector = createSelector(
  [selectedDataState], (selectedData) => {
    return selectedData;
  }
);

export const selectedTurnSelector = createSelector(
  [selectedTurnState], (selectedTurn) => {
    return selectedTurn;
  }
);

export const shallowEqualForResultSet = (
  result1: ResultDevilSet[],
  result2: ResultDevilSet[],
): boolean => {
  const boolList: boolean[] = [];

  boolList.push(result1.length === result2.length);

  if (!boolList.includes(false)) {
    for (let index = 0; index < result1.length; index++) {
      const element1 = result1[index];
      const element2 = result2[index];

      boolList.push(
        shallowEqualForDevil(element1.devil1, element2.devil1)
      );
      boolList.push(
        shallowEqualForDevil(element1.devil2, element2.devil2)
      );
      boolList.push(
        shallowEqualForDevil(element1.resultDevil, element2.resultDevil)
      );
    }
  }

  return !boolList.includes(false);
}

const shallowEqualForDevil = (
  devil1: Devil,
  devil2: Devil
): boolean => {
  return devil1.id === devil2.id;
}

export const shallowEqualForList = (
  list1: any[],
  list2: any[]
): boolean => {
  const boolList: boolean[] = [];

  boolList.push(Array.isArray(list1) && Array.isArray(list2))

  if (!boolList.includes(false)) {
    boolList.push(list1.length === list2.length);
  }

  if (!boolList.includes(false)) {
    for (let index = 0; index < list1.length; index++) {
      const element1 = list1[index];
      const element2 = list2[index];

      boolList.push(
        element1 === element2
      );
    }
  }

  return !boolList.includes(false);
}
