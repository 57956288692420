import React from 'react';
import './DevilContent.scss';
import { useHistory } from 'react-router-dom';
import { DevilResult } from '../../ducks/model';
import { useDispatch } from "react-redux";
import { ActionType } from '../../ducks/actions';
import { isSpecialPattern } from '../../common/CalcResult';
import { getBasePath } from '../../common/PathManager';

function DevilContent(props: DevilResult) {

  const { devil, color, accordion, series } = props;
  let devilImg = "";

  try {
    devilImg = require("../../assets/img/devils_" + series + "/" + devil.id.toString() + ".jpg");
  } catch {
    devilImg = require('../../assets/img/noimage.png');
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const devilChoice = () => {
    dispatch({ type: ActionType.DEVIL_CHOICE, devilData: devil });
    if (
      isSpecialPattern(
        devil,
        typeof series === 'undefined' ? '' : series
      )
    ) {
      history.push({
        pathname: getBasePath() + String(series).replace('_', '-') + '/special-fusion',
        state: {
          choice: devil
        }
      });
    } else {
      history.push(getBasePath() + String(series).replace('_', '-') + '/reverse-fusion');
    }
  };
  const devilAccordion = () => {
    ; // 何も処理しない
  };

  let element = ""
  switch (color) {
    case "devil1":
      element = "素材1"
      break
    case "devil2":
      element = "素材2"
      break
    case "sacrifice":
      element = "生贄"
      break
    case "result":
      element = "結果"
      break
    case "spirit-up":
      element = "結果↑"
      break
    case "spirit-down":
      element = "結果↓"
      break
  }

  return (
    <div
      className={
        'devil-content '
        + (color === "result" || accordion ? 'result' : '')
        + (devil.basicData.type === "－" ? ' no-result' : '')
        + (color === "spirit-up" || color === "spirit-down" ? 'spirit' : '')
        + (color === "devil1" ? 'devil1' : '')
        + (color === "devil2" ? 'devil2' : '')
      }
      onClick={() => {
        accordion ? devilAccordion() : devilChoice();
      }}>
      <div className="info">
        <div className="image-wrap">
          <img className="devil-image" src={devilImg} alt="devil-image" />
          {/* <div className="devil-init-name">
            <p>{devil.basicData.name.slice(0, 1)}</p>
          </div> */}
          <div
            className={"element "
              + (color === "result" ? 'result' : '')
              + (devil.basicData.type === "－" ? ' no-result' : '')
              + (color === "spirit-up" ? 'spirit-up' : '')
              + (color === "spirit-down" ? 'spirit-down' : '')
              + (color === "devil1" ? 'devil1' : '')
              + (color === "devil2" ? 'devil2' : '')
              + (color === "sacrifice" ? 'sacrifice' : '')
            }>{element}</div>
        </div>
        <div className="devil-info">
          <div className="level">Lv.{devil.basicData.level}</div>
          <div className="type">{devil.basicData.type}</div>
          <div className="moonAge">{devil.moonAge}</div>
        </div>
      </div>
      <div className="name">{devil.basicData.name}</div>
    </div>
  );

}

export default DevilContent;