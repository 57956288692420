
export type FusionSet = {
  race1: string;
  devil1: string;
  level1: number;
  race2: string;
  devil2: string;
  level2: number;
}

export type BasicDevilData = {
  type: string;
  name: string;
  level: number;
};

export type BasicRaceData = {
  type: string;
  name: string;
};

export type Devil = {
  id: number;
  attribute: string;
  basicData: BasicDevilData;
  moonAge?: string;
}

export type DevilResult = {
  devil: Devil;
  color?: string;
  accordion?: boolean;
  series: string
}

export type Race = {
  id: number;
  attribute: string;
  basicData: BasicRaceData;
}

export type Pattern = {
  id: number;
  race1_type: string;
  race1_name: string;
  race2_type: string;
  race2_name: string;
  result_type: string;
  result_name: string;
}

export type SpecialPattern = {
  id: number;
  resultBasicData: BasicDevilData;
  srcDevil: { id: number; name: string }[]
};

export type ResultDevilSet = {
  devil1: Devil;
  devil2: Devil;
  resultDevil: Devil;
}

export type ResultDisplayWrapper = {
  resultSet: ResultDevilSet;
  displayPtn?: boolean;
}

export type SpecialResultDevilSet = {
  id: number;
  resultDevil: Devil;
  srcDevil: Devil[]
}

export type ConditionState = {
  devil1: Devil;
  devil2: Devil;
  majin?: boolean;
  curse?: boolean;
}

export type ResultState = {
  normalResult: ResultDevilSet[];
  reverseResult: ResultDevilSet[];
  filterOn: boolean;
}

export type ChoiceState = {
  choiceDevil: Devil;
}

export type SortState = {
  open: boolean;
  selectedDevil: String;
  selectedData: String;
  selectedTurn: String;
}

export type AccordionState = {
  selectedId: number;
  openFlg: boolean;
}

export type State = {
  conditionState: ConditionState;
  resultState: ResultState;
  choiceState: ChoiceState;
  sortState: SortState;
  accordionState: AccordionState;
}

export type SpecialFusionForM3 = {
  id: number;
  resultBasicData: {
    type: string;
    name: string;
    level: number;
  };
  srcDevil: {
    ptn: string;
    devil1: {
      id?: number;
      name?: string;
      type?: string;
    }
    devil2?: {
      id?: number;
      name?: string;
      type?: string[];
    }
  };
  sacrifice: {
    ptn: string;
    data?: {
      id?: number;
      name?: string;
      type?: string;
    }
  }
};

export type SpecialResultDevilSetForM3 = {
  devil1: Devil;
  devil2: Devil;
  sacrifice?: Devil;
}

export const initialPattern: Pattern = {
  id: 0,
  race1_name: '',
  race1_type: '',
  race2_name: '',
  race2_type: '',
  result_name: '',
  result_type: ''
}

export const unselected: string = '種族全体';

export const initialDevil: Devil = {
  id: 0,
  attribute: '',
  basicData: {
    type: '大天使',
    name: unselected,
    level: 0
  },
  moonAge: ''
};

export const noResultForFusion: Devil = {
  id: 0,
  attribute: 'ーーー',
  basicData: {
    type: '－',
    name: '合体結果なし',
    level: 0
  }
};

export const selectDevil = [
  { key: "devil1", value: "素材1" },
  { key: "devil2", value: "素材2" },
  { key: "resultDevil", value: "結果" }
];

export const selectData = [
  { key: "level", value: "Level" },
  { key: "type", value: "種族" },
  { key: "name", value: "名前" }
];

export const selectTurn = [
  { key: "desc", value: "降順" },
  { key: "asc", value: "昇順" }
];

export const standardFusionPattern = {
  NORMAL: "NORMAL",
  SPIRIT: "SPIRIT",
  SAMERACE: "SAMERACE"
}

export const initialConditionState: ConditionState = {
  devil1: initialDevil,
  devil2: initialDevil,
  majin: false,
  curse: false
};

export const initialResultState: ResultState = {
  normalResult: [],
  reverseResult: [],
  filterOn: false
};

export const initialChoiceState: ChoiceState = {
  choiceDevil: initialDevil
};

export const initialSortState: SortState = {
  open: false,
  selectedDevil: "devil1",
  selectedData: "level",
  selectedTurn: "desc"
}

export const initialAccordionState: AccordionState = {
  selectedId: 0,
  openFlg: false
}

export const initialState: State = {
  conditionState: initialConditionState,
  resultState: initialResultState,
  choiceState: initialChoiceState,
  sortState: initialSortState,
  accordionState: initialAccordionState,
}
