import React from 'react';
import './Footer.scss';
import { useHistory } from 'react-router-dom';
import { getBasePath } from '../../common/PathManager';

function Footer(props) {
  const { series } = props;

  const history = useHistory();
  const handleOnClickDevilList = () => {
    history.push(getBasePath() + String(series).replace('_', '-') + '/devil-list');
  };
  const handleOnClickStandardFusion = () => {
    history.push(getBasePath() + String(series).replace('_', '-') + '/devil-fusion');
  };
  const handleOnClickSpecialFusion = () => {
    history.push(getBasePath() + String(series).replace('_', '-') + '/special-fusion');
  };

  const iconDevilList = require('../../assets/img/devil_black.png');
  // const iconStandardFusion = require('../../assets/img/fusion_icon.png');

  return (
    <div className="footer">
      <div className="footer-button" onClick={handleOnClickDevilList}>
        <div className="button-name">悪魔一覧</div>
        <img className="devil-image" src={iconDevilList} alt="iconDevilList" />
      </div>
      <div className="footer-button" onClick={handleOnClickStandardFusion}>
        <div className="button-name">二身合体</div>
        <img className="devil-image" src={iconDevilList} alt="iconStandardFusion" />
      </div>
      <div className="footer-button" onClick={handleOnClickSpecialFusion}>
        <div className="button-name">特殊合体</div>
        <img className="devil-image" src={iconDevilList} alt="iconStandardFusion" />
      </div>
    </div>
  );

}

export default Footer;