import * as winston from "winston";
import stageInfo from '../config/log-config.json';

export class Logger {
  private logger: winston.Logger;
  private stage: string;

  constructor(stage: string) {
    this.logger = winston.createLogger(undefined);
    this.stage = stage;
  }

  public async initialize(): Promise<winston.Logger> {
    this.logger = winston.createLogger({
      level: "info",
      format: winston.format.combine(
        winston.format.timestamp(),
        winston.format.cli(),
        winston.format.printf(info => `[${info.timestamp}] ${info.level} ${info.message}`)
      ),
      // defaultMeta: { service: "winston-lambda" },
      transports: new winston.transports.Console()
    });

    // 検証環境の場合、loggingをdebugレベルまで上げる
    if (this.stage !== "sakura") {
      // clear()をする事によって、createLoggerの際に指定したtransportsの設定を消せる
      this.logger.clear();
      this.logger.add(
        new winston.transports.Console({
          level: "debug"
        })
      );
    }

    return this.logger;
  }
}

export const LoggingManager = async (
  messageList: any[],
  level?: string
) => {
  const stage = typeof stageInfo === 'undefined' ? '' : stageInfo.service.stage;
  const logger = await new Logger(stage).initialize();
  let logLevel = typeof level === 'undefined' ? 'debug' : level;
  let message = "";
  messageList.forEach(m => {
    if (message === "") {
      message = logAppender(message, m, true);
    } else {
      message = logAppender(message, m, false);
    }
  });
  logger.log({
    level: logLevel,
    message: message
  });
}

const logAppender = (
  srcMsg: string,
  msg: any,
  isFirst: boolean
): string => {
  const pad: string = isFirst ? '' : ' ';
  if (typeof msg === 'object') {
    srcMsg = srcMsg + pad + JSON.stringify(msg);
  } else {
    srcMsg = srcMsg + pad + msg;
  }
  return srcMsg;
}



