import { getFilterDevilList } from '../../common/DataSelector'
import { convertRaceName, getResultForSameRace } from '../../common/CalcResult'
import {
  ResultDevilSet,
  noResultForFusion,
  Devil,
  unselected
} from '../../ducks/model';

const SameRaceFusion = (
  devil1: Devil, devil2: Devil, series: string, curse: boolean
) => {

  // 合体結果の悪魔
  const resultDevil = curse
    ? noResultForFusion
    : convertRaceName(
      getResultForSameRace(devil1, series),
      series
    )
    ;
  // 合体元悪魔の種族に属するリスト
  const sameRaceDevilList = getFilterDevilList(devil1.basicData.type, series);

  let resultDevilSet: ResultDevilSet[] = [{
    devil1: devil1,
    devil2: devil2,
    resultDevil: noResultForFusion,
  }];
  resultDevilSet.pop()

  // 悪魔１がAnyの場合
  if (devil1.basicData.name === unselected) {
    sameRaceDevilList.forEach((data) => {
      resultDevilSet.push({
        devil1: convertRaceName(data, series),
        devil2: devil2,
        resultDevil: data.basicData.name === devil2.basicData.name
          ? noResultForFusion
          : resultDevil
      });
    });
  }
  // 悪魔２がAnyの場合
  else if (devil2.basicData.name === unselected) {
    sameRaceDevilList.forEach((data) => {
      resultDevilSet.push({
        devil1: devil1,
        devil2: convertRaceName(data, series),
        resultDevil: data.basicData.name === devil1.basicData.name
          ? noResultForFusion
          : resultDevil
      });
    });
  }
  // 悪魔１・２とも固定の場合
  else {
    resultDevilSet = [{
      devil1: devil1,
      devil2: devil2,
      resultDevil: devil1.basicData.name === devil2.basicData.name
        ? noResultForFusion
        : resultDevil,
    }];
  }

  return resultDevilSet;
};

export default SameRaceFusion;
