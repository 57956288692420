import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import SpecialFusionResultForM3 from '../../components/SpecialFusionResult/SpecialFusionResultForM3';
import { Devil, initialDevil, SpecialResultDevilSetForM3 } from '../../ducks/model';
import * as rm from '../../common/ResourceManager';
import { specialPatternResult } from '../../common/CalcResult';
import { extractRacesFromResult, extractDevilsFromResult, filterResultForRace, filterResultForDevil } from '../../common/DataSelector';
import { Filter } from '../../components/Filter/Filter';
import { LoggingManager as logger } from '../../common/CommonLogger';
import Loading from '../../components/Loading/Loading';
import './SpecialFusionForM3.scss';

type Props = {
  series: string;
  devil: Devil;
}
const SpecialFusionM3Main = (props: Props) => {
  const { series, devil } = props
  const [fusionDatas, setFusionDatas] = useState<SpecialResultDevilSetForM3[]>(
    devil === initialDevil ? [] : specialPatternResult(devil.basicData.name)
  );
  const [memoryFusionDatas, setMemoryFusionDatas]
    = useState<SpecialResultDevilSetForM3[]>(
      devil === initialDevil ? [] : specialPatternResult(devil.basicData.name)
    );
  const [devilName, setDevilName] = useState(
    devil === initialDevil ? null : devil.basicData.name
  );
  const [resetFlg, setResetFlg] = useState(false);
  const devilList: string[] = rm.importJsonForPatternOfSpecial(series).map(
    value => {
      return value.resultBasicData.name;
    }
  );
  const [filterRaceList1, setFilterRaceList1] = useState<string[]>(
    extractRacesFromResult(memoryFusionDatas, "devil1", series)
  );
  const [filterRaceList2, setFilterRaceList2] = useState<string[]>(
    extractRacesFromResult(memoryFusionDatas, "devil2", series)
  );
  const [filterRaceList3, setFilterRaceList3] = useState<string[]>(
    extractRacesFromResult(memoryFusionDatas, "sacrifice", series)
  );
  const [filterDevilList1, setFilterDevilList1] = useState<string[]>(
    extractDevilsFromResult(memoryFusionDatas, "devil1")
  );
  const [filterDevilList2, setFilterDevilList2] = useState<string[]>(
    extractDevilsFromResult(memoryFusionDatas, "devil2")
  );
  const [filterDevilList3, setFilterDevilList3] = useState<string[]>(
    extractDevilsFromResult(memoryFusionDatas, "sacrifice")
  );

  const changeTargetList = useCallback(
    (value: string | null) => {
      if (value) {
        setDevilName(value);
        const _fusionDatas = specialPatternResult(value);
        setFusionDatas(_fusionDatas);
        setMemoryFusionDatas(_fusionDatas);
      }
    }, []
  );

  const listReset = useCallback(
    (filterResultList: SpecialResultDevilSetForM3[]) => {
      setFilterRaceList1(extractRacesFromResult(filterResultList, "devil1", series));
      setFilterRaceList2(extractRacesFromResult(filterResultList, "devil2", series));
      setFilterRaceList3(extractRacesFromResult(filterResultList, "sacrifice", series));
      setFilterDevilList1(extractDevilsFromResult(filterResultList, "devil1"));
      setFilterDevilList2(extractDevilsFromResult(filterResultList, "devil2"));
      setFilterDevilList3(extractDevilsFromResult(filterResultList, "sacrifice"));
      if (resetFlg) setFusionDatas(filterResultList);
    }, [series, resetFlg]
  );

  const changeRaceList1 = useCallback(
    (race: string) => {
      const filterResultList: SpecialResultDevilSetForM3[] = filterResultForRace(
        fusionDatas,
        "devil1",
        race ? race : '',
        series,
      );
      listReset(filterResultList);
    }, [fusionDatas, series, listReset]
  );
  const changeRaceList2 = useCallback(
    (race: string) => {
      const filterResultList: SpecialResultDevilSetForM3[] = filterResultForRace(
        fusionDatas,
        "devil2",
        race ? race : '',
        series,
      );
      listReset(filterResultList);
    }, [fusionDatas, series, listReset]
  );
  const changeRaceList3 = useCallback(
    (race: string) => {
      const filterResultList: SpecialResultDevilSetForM3[] = filterResultForRace(
        fusionDatas,
        "sacrifice",
        race ? race : '',
        series,
      );
      listReset(filterResultList);
    }, [fusionDatas, series, listReset]
  );
  const changeDevilList1 = useCallback(
    (devil: string) => {
      const filterResultList: SpecialResultDevilSetForM3[] = filterResultForDevil(
        fusionDatas,
        "devil1",
        devil ? devil : '',
      );
      listReset(filterResultList);
    }, [fusionDatas, listReset]
  );
  const changeDevilList2 = useCallback(
    (devil: string) => {
      const filterResultList: SpecialResultDevilSetForM3[] = filterResultForDevil(
        fusionDatas,
        "devil2",
        devil ? devil : '',
      );
      listReset(filterResultList);
    }, [fusionDatas, listReset]
  );
  const changeDevilList3 = useCallback(
    (devil: string) => {
      const filterResultList: SpecialResultDevilSetForM3[] = filterResultForDevil(
        fusionDatas,
        "sacrifice",
        devil ? devil : '',
      );
      listReset(filterResultList);
    }, [fusionDatas, listReset]
  );

  const filterClear = useCallback(() => {
    listReset(memoryFusionDatas);
  }, [memoryFusionDatas, listReset]);

  useEffect(() => {
    listReset(fusionDatas);
    setResetFlg(true);
  }, [fusionDatas, listReset, setResetFlg]);

  logger(['SpecialFusion', fusionDatas]);

  return (
    <div className="special-m3-wrapper">
      <Loading display={false}></Loading>
      <div className="filter-devil devil1">
        <Autocomplete
          id="filter-combo-devil1-name"
          value={devilName}
          options={devilList}
          onChange={(event: any, value: string | null) => { changeTargetList(value) }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref} className="combo-wrapper">
              <input type="text" {...params.inputProps} className="combo" placeholder="お選びください" />
            </div>
          )}
        />
      </div>
      <div className="main-contents">
        {memoryFusionDatas.length > 1 &&
          <Filter
            series={series}
            filterRaceList1={filterRaceList1}
            filterRaceList2={filterRaceList2}
            filterRaceList3={filterRaceList3}
            filterDevilList1={filterDevilList1}
            filterDevilList2={filterDevilList2}
            filterDevilList3={filterDevilList3}
            changeRaceList1={changeRaceList1}
            changeRaceList2={changeRaceList2}
            changeRaceList3={changeRaceList3}
            changeDevilList1={changeDevilList1}
            changeDevilList2={changeDevilList2}
            changeDevilList3={changeDevilList3}
            filterClear={filterClear}
          />
        }
        {Object.keys(fusionDatas).map(i => (
          <SpecialFusionResultForM3
            key={i}
            devil1={fusionDatas[i].devil1}
            devil2={fusionDatas[i].devil2}
            sacrifice={fusionDatas[i].sacrifice}
            series={series}
          ></SpecialFusionResultForM3>
        ))}
      </div>
    </div>
  );
}

export const SpecialFusionM3 = React.memo(SpecialFusionM3Main);
