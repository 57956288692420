import React from 'react';
import { FusionResult } from '../../components/FusionResult/FusionResult';
import { connect, useDispatch } from 'react-redux'
import {
  getMajinResult,
  getStandardFusionPattern,
  isSpirit,
  majinConverter
} from '../../common/CalcResult'
import { ActionType } from '../../ducks/actions'
import {
  Devil,
  ResultDevilSet,
  standardFusionPattern,
  unselected
} from '../../ducks/model';

import StandardFusion from '../FusionLogics/StandardFusion';
import SpiritXSpiritFusion from '../FusionLogics/SpiritXSpiritFusion';
import SameRaceFusion from '../FusionLogics/SameRaceFusion';
import SpiritFusion from '../FusionLogics/SpiritFusion';

import {
  devil1Selector,
  devil2Selector,
  selectedDevilSelector,
  selectedDataSelector,
  selectedTurnSelector,
  normalResultSelector,
  curseSelector, majinSelector,
} from '../../ducks/selector';
import { LoggingManager as logger } from '../../common/CommonLogger';
import Loading from '../Loading/Loading';

// 合体結果の算出が必要かを判定する変数
let calcFlg: boolean = false;

/**
 * FusionWrapper
 *   - 二身合体メインコンポーネント
 * 
 * @param props - mapStateProps
 */
const FusionWrapper = (props) => {

  // props から情報抽出
  const { devil1, devil2, series, curse, majin, normalResult } = props;
  const fixColumn = getFixColumn(devil1.basicData.name, devil2.basicData.name);
  const dispatch = useDispatch();

  // 合体結果の格納用変数
  let resultDevilSet: ResultDevilSet[] = normalResult;

  // devil1, devil2 のいずれも種族全体であれば、空の合体結果を登録する
  if (
    (devil1 as Devil).basicData.name === unselected &&
    (devil2 as Devil).basicData.name === unselected
  ) {
    resultDevilSet = [];
    // 合体結果のdispatch
    dispatch({
      type: ActionType.NORMAL_RESULT_SET,
      resultDevilSet: [],
    });
  }
  // devil1, devil2 のいずれかが変更されている場合、合体結果の算出を行う
  else if (calcFlg) {
    // 二身合体のパターンの特定
    const stdFusionPtn: string = getStandardFusionPattern(devil1, devil2);

    if (devil1.basicData.name !== unselected || devil2.basicData.name !== unselected) {
      // 同種族合体（精霊×精霊）
      if (stdFusionPtn === standardFusionPattern.SAMERACE && isSpirit(devil1)) {
        logger(["SpiritXSpiritFusion Pattern"]);
        resultDevilSet = SpiritXSpiritFusion(devil1, devil2, series);
      }
      // 同種族合体
      else if (stdFusionPtn === standardFusionPattern.SAMERACE) {
        logger(["SameRaceFusion Pattern"]);
        resultDevilSet = SameRaceFusion(devil1, devil2, series, curse);
      }
      // 精霊合体
      else if (stdFusionPtn === standardFusionPattern.SPIRIT) {
        logger(["SpiritFusion Pattern"]);
        resultDevilSet = SpiritFusion(devil1, devil2, series, curse);
      }
      // 通常二身合体
      else {
        logger(["StandardFusion Pattern"]);
        resultDevilSet = StandardFusion(devil1, devil2, series, curse);
      }

      if (majin && getMajinResult(resultDevilSet[0].resultDevil) !== []) {
        resultDevilSet = majinConverter(resultDevilSet);
      }
    }

    // 合体結果のdispatch
    dispatch({
      type: ActionType.NORMAL_RESULT_SET,
      resultDevilSet: resultDevilSet,
    });
  }

  return (
    <div>
      <Loading display={false}></Loading>
      <FusionResult
        resultDevilSet={resultDevilSet}
        displayPtn={true}
        fixColumn={fixColumn}
        series={series}
      />
    </div>
  );
};

/**
 * getFixColumn
 *   - any でない方の列(devil1 or devil2)を特定する
 * 
 * @param name1 - devil1 の悪魔の名前
 * @param name2 - devil2 の悪魔の名前
 * @returns - devil1, devil2 で any でない方の列(devil1 or devil2)
 *          - どちらも any または どちらも any でない場合は空文字
 */
function getFixColumn(name1: string, name2: string): string {
  const anyName = unselected;
  if (anyName === name1 && anyName !== name2) {
    return 'devil2';
  }
  else if (anyName !== name1 && anyName === name2) {
    return 'devil1';
  }
  else {
    return '';
  }
}

/**
 * mapStateProps
 *   - state から props を設定する
 * 
 * @param state - state
 */
function mapStateProps(state) {
  return {
    normalResult: normalResultSelector(state),
    devil1: devil1Selector(state),
    devil2: devil2Selector(state),
    majin: majinSelector(state),
    curse: curseSelector(state),
    selectedDevil: selectedDevilSelector(state),
    selectedData: selectedDataSelector(state),
    selectedTurn: selectedTurnSelector(state),
  };
}

/**
 * MemoizedFusionWrapper
 *   - memo化した FusionWrapper
 */
export const MemoizedFusionWrapper = React.memo(
  connect(
    mapStateProps,
    null,
    null,
    {
      pure: true,
      areStatePropsEqual(next, prev) {
        logger(["FusionWrapper state compare next:", next]);
        logger(["FusionWrapper state compare prev:", prev]);

        const nameChangeCheck: boolean = (
          next.devil1.basicData.name === prev.devil1.basicData.name &&
          next.devil2.basicData.name === prev.devil2.basicData.name
        );
        const sortParamChangeCheck: boolean = (
          next.selectedDevil === prev.selectedDevil &&
          next.selectedData === prev.selectedData &&
          next.selectedTurn === prev.selectedTurn
        );

        const optionChangeCheck: boolean = (
          next.curse === prev.curse &&
          next.majin === prev.majin
        );

        calcFlg = !nameChangeCheck || !optionChangeCheck;

        return nameChangeCheck && sortParamChangeCheck && optionChangeCheck;
      }
    }
  )
    (FusionWrapper)
);

