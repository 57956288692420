import { ActionType } from './actions'
import {
  getFilterDevilList,
  getDevilInfoByName,
  getRaceInfoByType, getInitialDevilList
} from '../common/DataSelector'
import {
  initialConditionState,
  initialChoiceState,
  initialSortState,
  initialResultState,
  initialAccordionState,
  unselected,
  initialDevil
} from './model'
import { LoggingManager as logger } from '../common/CommonLogger';

export const conditionReducer = (state = initialConditionState, action) => {
  switch (action.type) {
    case ActionType.RACE1_CHANGE:
      logger(['RACE1 LIST IS CHANGE.']);
      return Object.assign({}, state, {
        devil1: {
          id: getFilterDevilList(action.race1, action.series)[0].id,
          attribute: getFilterDevilList(action.race1, action.series)[0].attribute,
          basicData: {
            type: action.race1,
            name: getFilterDevilList(action.race1, action.series)[0].basicData.name,
            level: getFilterDevilList(action.race1, action.series)[0].basicData.level
          }
        }
      });
    case ActionType.DEVIL1_CHANGE:
      logger(['DEVIL1 LIST IS CHANGE.']);
      return Object.assign({}, state, {
        devil1: {
          id: action.devil1 !== unselected
            ? getDevilInfoByName(action.devil1, action.series).id
            : state.devil1.id,
          attribute: action.devil1 !== unselected
            ? getDevilInfoByName(action.devil1, action.series).attribute
            : state.devil1.attribute,
          basicData: {
            name: action.devil1,
            type: action.devil1 !== unselected
              ? getRaceInfoByType(
                getDevilInfoByName(
                  action.devil1, action.series
                ).basicData.type, action.series
              ).basicData.name
              : state.devil1.basicData.type,
            level: action.devil1 !== unselected
              ? getDevilInfoByName(action.devil1, action.series).basicData.level
              : state.devil1.basicData.level
          }
        }
      });
    case ActionType.RACE2_CHANGE:
      logger(['RACE2 LIST IS CHANGE.']);
      return Object.assign({}, state, {
        devil2: {
          id: getFilterDevilList(action.race2, action.series)[0].id,
          attribute: getFilterDevilList(action.race2, action.series)[0].attribute,
          basicData: {
            type: action.race2,
            name: getFilterDevilList(action.race2, action.series)[0].basicData.name,
            level: getFilterDevilList(action.race2, action.series)[0].basicData.level
          }
        }
      });
    case ActionType.DEVIL2_CHANGE:
      logger(['DEVIL2 LIST IS CHANGE.']);
      return Object.assign({}, state, {
        devil2: {
          id: action.devil2 !== unselected
            ? getDevilInfoByName(action.devil2, action.series).id
            : state.devil2.id,
          attribute: action.devil2 !== unselected
            ? getDevilInfoByName(action.devil2, action.series).attribute
            : state.devil2.attribute,
          basicData: {
            name: action.devil2,
            type: action.devil2 !== unselected
              ? getRaceInfoByType(
                getDevilInfoByName(
                  action.devil2, action.series
                ).basicData.type, action.series
              ).basicData.name
              : state.devil2.basicData.type,
            level: action.devil2 !== unselected
              ? getDevilInfoByName(action.devil2, action.series).basicData.level
              : state.devil2.basicData.level
          }
        }
      });
    case ActionType.MAJIN_CHECK_CHANGE:
      logger(['MAJIN CHECK CHANGE.']);
      return Object.assign({}, state, {
        majin: action.majin
      });
    case ActionType.CURSE_CHECK_CHANGE:
      logger(['CURSE CHECK CHANGE.']);
      return Object.assign({}, state, {
        curse: action.curse
      });
    case ActionType.DEVIL_LIST_RESET:
      logger(['DEVIL LIST RESET.']);
      return Object.assign({}, state, {
        devilList1: getInitialDevilList(action.series),
        devilList2: getInitialDevilList(action.series),
        devil1: initialDevil,
        devil2: initialDevil
      });
    default:
      logger(['conditionReducer DEFAULT']);
      return state;
  }
}

export const resultReducer = (state = initialResultState, action) => {
  switch (action.type) {
    case ActionType.NORMAL_RESULT_SET:
      logger(['NORMAL RESULT SET.']);
      return Object.assign({}, state, {
        normalResult: action.resultDevilSet,
      });
    case ActionType.REVERSE_RESULT_SET:
      logger(['REVERSE RESULT SET.']);
      return Object.assign({}, state, {
        filterRaceList1: action.filterRaceList1,
        filterRaceList2: action.filterRaceList2,
        filterDevilList1: action.filterDevilList1,
        filterDevilList2: action.filterDevilList2,
        reverseResult: action.resultDevilSet,
      });
    case ActionType.RESULT_CLEAR:
      return initialResultState;
    default:
      logger(['resultReducer REFAULT']);
      return state;
  }
}

export const choiceReducer = (state = initialChoiceState, action) => {
  switch (action.type) {
    case ActionType.DEVIL_CHOICE:
      logger(['DEVIL CHOICE.']);
      return Object.assign({}, state, {
        choiceDevil: action.devilData
      });
    case ActionType.CHOICE_CLEAR:
      return initialChoiceState;
    default:
      logger(['choiceReducer REFAULT']);
      return state;
  }
}

export const sortReducer = (state = initialSortState, action) => {
  switch (action.type) {
    case ActionType.SELECTED_DEVIL:
      logger(['SELECTED_DEVIL']);
      return Object.assign({}, state, {
        selectedDevil: action.event
      })
    case ActionType.SELECTED_DATA:
      logger(['SELECTED_DATA']);
      return Object.assign({}, state, {
        selectedData: action.event
      })
    case ActionType.SELECTED_TURN:
      logger(['SELECTED_TURN']);
      return Object.assign({}, state, {
        selectedTurn: action.event
      })
    case ActionType.MODAL_OPEN:
      logger(['MODAL_OPEN']);
      return Object.assign({}, state, {
        open: action.b
      });
    case ActionType.MODAL_CLOSE:
      logger(['MODAL_CLOSE']);
      return Object.assign({}, state, {
        open: action.b
      });
    default:
      logger(['sortReducer DEFAULT']);
      return state;
  }
}

export const accordionReducer = (state = initialAccordionState, action) => {
  switch (action.type) {
    case ActionType.ACCORDION_DEVIL:
      logger(['ACCORDION_DEVIL.']);
      return Object.assign({}, state, {
        selectedId: action.selectedId,
        openFlg: action.openFlg
      });
    default:
      logger(['accordionReducer REFAULT']);
      return state;
  }
}

