import {
  getFilterDevilList,
  getDevilInfoByName,
  getRaceInfoByType,
  getDevilInfoById
} from './DataSelector'
import {
  Pattern,
  Devil,
  ResultDevilSet,
  initialPattern,
  initialDevil,
  noResultForFusion,
  standardFusionPattern,
  unselected, SpecialFusionForM3, SpecialResultDevilSetForM3
} from '../ducks/model';
import * as rm from '../common/ResourceManager'
import { LoggingManager as logger } from '../common/CommonLogger';

/**
 * 通常二身合体の結果を算出する
 * @param patternResult 二身合体パターン
 * @param level         合体結果算出用レベル（元悪魔のレベル合計÷2 +1）
 * @param series        シリーズ情報
 */
export function getResult(
  patternResult: Pattern, level: number, series: string
) {
  let continueFlag = true;
  let maxLevelDevil: Devil = noResultForFusion;
  let resultDevil: Devil = noResultForFusion;

  const tmpDevilList: Devil[] = [];
  getFilterDevilList(patternResult.result_name, series).forEach(
    data => {
      if (!isSpecialPattern(data, series)) {
        tmpDevilList.push(data)
      }
    }
  );

  if (typeof patternResult !== "undefined") {
    if (patternResult.id !== 0 && level !== 0) {
      tmpDevilList.forEach((result) => {
        if (continueFlag) {
          if (result.basicData.level >= level) {
            resultDevil = result;
            continueFlag = false;
          }
          maxLevelDevil = result;
        }
      });
    }
    if (resultDevil === noResultForFusion) {
      resultDevil = maxLevelDevil;
    }
  }

  return resultDevil;
}

/**
 * 精霊×精霊合体の合体結果を算出する
 * @param devil1 精霊悪魔情報１
 * @param devil2 精霊悪魔情報２
 * @param series シリーズ情報
 */
export function getResultForSpiritXSpirit(
  devil1: Devil, devil2: Devil, series: string
) {
  let resultMitama: string = '';
  rm.importJsonForPatternOfSpiritX(series).forEach((data) => {
    if (data.src1 === devil1.basicData.name && data.src2 === devil2.basicData.name) {
      resultMitama = data.result;
    }
  });

  return resultMitama === "None" ? noResultForFusion : getDevilInfoByName(resultMitama, series);
}

/**
 * 同種族合体の結果を算出する
 * @param srcDevil 種族取得用の悪魔情報
 * @param series   シリーズ情報
 */
export function getResultForSameRace(srcDevil: Devil, series: string) {

  let resultSpirit: string = '';
  rm.importJsonForPatternOfSameRace(series).forEach((data) => {
    if (data.race_name === srcDevil.basicData.type) {
      resultSpirit = data.result_name;
    }
  });

  return resultSpirit === "None" ? noResultForFusion : getDevilInfoByName(resultSpirit, series);
}

/**
 * 精霊合体の結果を算出する
 * @param baseDevil   精霊ではない方の悪魔情報
 * @param spiritDevil 精霊悪魔の情報
 * @param series      シリーズ情報
 * @param curse       呪い合体かどうか
 */
export function getResultForSpirit(
  baseDevil: Devil, spiritDevil: Devil, series: string, curse: boolean
) {

  const rankUpPatternData = rm.importJsonForPatternOfSpirit(series).find((data) => {
    return data.race_name === baseDevil.basicData.type && data.spirit_name === spiritDevil.basicData.name;
  });

  let resultDevil: Devil = noResultForFusion;
  if (typeof rankUpPatternData === "undefined") {
    resultDevil = noResultForFusion;
  }
  else {
    const devilList = getFilterDevilList(baseDevil.basicData.type, series);
    const idList: number[] = devilList.map((data) => {
      return data.id;
    });
    const delta = curse
      ? rankUpPatternData.result_ptn * (-1)
      : rankUpPatternData.result_ptn;
    const rank = baseDevil.id + delta;
    if (rank === baseDevil.id) {
      resultDevil = noResultForFusion;
    }
    else if (idList.includes(rank)) {
      resultDevil = getDevilInfoById(rank, series);
    }
  }

  return resultDevil;
}

/**
 * 合体結果の一覧（片方Any）を取得する
 * @param patternResult 合体パターン
 * @param devil1        悪魔情報１
 * @param devil2        悪魔情報２
 * @param bindLevel     Anyではない方の悪魔のレベル
 * @param race          Anyの方の悪魔の種族
 * @param series        シリーズ情報
 */
export function getResultList(
  patternResult: Pattern,
  devil1: string, devil2: string, bindLevel: number, race: string, series: string
) {

  let resultDevilSet: ResultDevilSet[] = [{
    devil1: getDevilInfoByName(devil1, series),
    devil2: getDevilInfoByName(devil2, series),
    resultDevil: initialDevil,
  }];
  resultDevilSet.pop();

  const tmpDevilList = getFilterDevilList(race, series);

  tmpDevilList.forEach((freeDevil) => {
    const newLevel = (bindLevel + freeDevil.basicData.level) / 2 + 1;
    if (devil1 === unselected) {
      resultDevilSet.push({
        devil1: convertRaceName(freeDevil, series),
        devil2: convertRaceName(getDevilInfoByName(devil2, series), series),
        resultDevil: convertRaceName(getResult(patternResult, newLevel, series), series),
      });
    } else {
      resultDevilSet.push({
        devil1: convertRaceName(getDevilInfoByName(devil1, series), series),
        devil2: convertRaceName(freeDevil, series),
        resultDevil: convertRaceName(getResult(patternResult, newLevel, series), series),
      });
    }
  });

  return resultDevilSet;
}

/**
 * 合体結果の一覧（片方Any）を取得する　※逆引き合体用
 * @param targetDevilData 逆引き元の悪魔情報
 * @param patternResult   逆引きパターン
 * @param series          シリーズ情報
 */
export function getResultListForReverseFusion(
  targetDevilData: Devil,
  patternResult: Pattern[],
  series: string
) {
  const _isMajinForM3: boolean = series === "m_3" && isMajinForM3(targetDevilData);
  const resultDevilSet: ResultDevilSet[] = [];

  patternResult.forEach((ptn) => {
    const srcDevilList1 = getFilterDevilList(ptn.race1_name, series);
    const srcDevilList2 = getFilterDevilList(ptn.race2_name, series);
    const skipList: string[] = [];

    srcDevilList1.forEach(srcDevil1 => {
      srcDevilList2.forEach(srcDevil2 => {
        const concatName1: string = srcDevil1.basicData.name + srcDevil2.basicData.name;
        const concatName2: string = srcDevil2.basicData.name + srcDevil1.basicData.name;
        if (
          srcDevil1.basicData.name !== srcDevil2.basicData.name &&
          !(skipList.includes(concatName1) || skipList.includes(concatName2))
        ) {
          if (_isMajinForM3) {
            getMoonAge(targetDevilData).forEach(r => {
              const rd: Devil = convertRaceName(targetDevilData, series);
              resultDevilSet.push({
                devil1: convertRaceName(srcDevil1, series),
                devil2: convertRaceName(srcDevil2, series),
                resultDevil: {
                  id: rd.id,
                  attribute: rd.attribute,
                  basicData: rd.basicData,
                  moonAge: r
                }
              })
            });
          } else {
            const avgLevel = (srcDevil1.basicData.level + srcDevil2.basicData.level) / 2 + 1;
            const tmpResultDevil = getResult(ptn, avgLevel, series);

            if (tmpResultDevil.basicData.name === targetDevilData.basicData.name) {
              resultDevilSet.push({
                devil1: convertRaceName(srcDevil1, series),
                devil2: convertRaceName(srcDevil2, series),
                resultDevil: convertRaceName(tmpResultDevil, series),
              });
            }
          }
          skipList.push(concatName1);
          skipList.push(concatName2);
        }
      })
    });
  });

  return resultDevilSet;
}

/**
 * 通常二身合体の合体パターンを取得する
 * @param devilData1 悪魔情報１
 * @param devilData2 悪魔情報２
 * @param series     シリーズ情報
 * @param curse      呪い合体かどうか
 */
export function getPattern(
  devilData1: Devil, devilData2: Devil, series: string, curse: boolean
) {

  let tmpPatternResult: Pattern = initialPattern;
  const resource = curse
    ? rm.importJsonForPatternOfCurse
    : rm.importJsonForPatternOfStandard;

  resource(series).forEach((tmpData) => {
    if (devilData1.basicData.type === tmpData.race1_name &&
      devilData2.basicData.type === tmpData.race2_name) {

      tmpPatternResult = {
        id: tmpData.id,
        race1_name: tmpData.race1_name,
        race1_type: tmpData.race1_type,
        race2_name: tmpData.race2_name,
        race2_type: tmpData.race2_type,
        result_name: tmpData.result_name,
        result_type: tmpData.result_type,
      };

    } else if (devilData2.basicData.type === tmpData.race1_name &&
      devilData1.basicData.type === tmpData.race2_name) {

      tmpPatternResult = {
        id: tmpData.id,
        race1_name: tmpData.race1_name,
        race1_type: tmpData.race1_type,
        race2_name: tmpData.race2_name,
        race2_type: tmpData.race2_type,
        result_name: tmpData.result_name,
        result_type: tmpData.result_type,
      };
    }
  });

  return tmpPatternResult;
}

/**
 * 逆引き合体の合体パターンを取得する
 * @param devilData 逆引き元の悪魔情報
 * @param series    シリーズ情報
 * @param curse     呪い合体かどうか
 */
export function getReversePattern(devilData: Devil, series: string, curse: boolean) {

  const tmpPatternResult: Pattern[] = [];
  const _isMajinForM3: boolean = series === "m_3" && isMajinForM3(devilData);
  const compareType: string = _isMajinForM3
    ? getReversePatternForMajin(devilData)
    : devilData.basicData.type;
  const resource = curse
    ? rm.importJsonForPatternOfCurse
    : rm.importJsonForPatternOfStandard;

  resource(series).forEach((tmpData) => {
    const raceInfo = getRaceInfoByType(tmpData.result_type, series);
    if (typeof raceInfo !== "undefined") {
      if (compareType === raceInfo.basicData.name) {

        tmpPatternResult.push({
          id: tmpData.id,
          race1_name: tmpData.race1_name,
          race1_type: tmpData.race1_type,
          race2_name: tmpData.race2_name,
          race2_type: tmpData.race2_type,
          result_name: _isMajinForM3 ? "魔人" : tmpData.result_name,
          result_type: _isMajinForM3 ? "Majin" : tmpData.result_type,
        });
      }
    }
  });

  return tmpPatternResult;
}

/**
 * 悪魔情報の種族名を英名から日本語に変換する
 * @param targetDevilData 変換対象の悪魔情報
 * @param series          シリーズ情報
 */
export function convertRaceName(targetDevilData: Devil, series: string) {
  const raceInfo = getRaceInfoByType(targetDevilData.basicData.type, series);
  if (typeof raceInfo === "undefined") {
    return targetDevilData;
  }

  const tmpDevilData: Devil = {
    id: targetDevilData.id,
    attribute: targetDevilData.attribute,
    basicData: {
      type: raceInfo.basicData.name,
      name: targetDevilData.basicData.name,
      level: targetDevilData.basicData.level
    }
  }

  return tmpDevilData;
}

/**
 * 逆引き合体の結果を算出する
 * @param targetDevilData 逆引き元の悪魔情報
 * @param series          シリーズ情報
 * @param curse           呪い合体かどうか
 */
export function getReverseResult(
  targetDevilData: Devil, series: string, curse: boolean
) {
  logger(['getReverseResult', targetDevilData]);
  const reversePattern: Pattern[] = getReversePattern(targetDevilData, series, curse);
  return getResultListForReverseFusion(targetDevilData, reversePattern, series);
}

/**
 * 特殊合体の悪魔か判別する
 * @param devilData 判別対象の悪魔情報
 * @param series    シリーズ情報
 */
export function isSpecialPattern(devilData: Devil, series: string) {
  return specialPatternList(series).includes(devilData.basicData.name);
}

/**
 * 合体パターンを判別する
 * @param devil1 悪魔情報１
 * @param devil2 悪魔情報２
 */
export function getStandardFusionPattern(devil1: Devil, devil2: Devil) {
  if (devil1.basicData.type === devil2.basicData.type) {
    return standardFusionPattern.SAMERACE;
  } else if (isSpirit(devil1) || isSpirit(devil2)) {
    return standardFusionPattern.SPIRIT;
  } else {
    return standardFusionPattern.NORMAL;
  }
}

/**
 * 精霊悪魔かどうか判別する
 * @param devil 判別対象の悪魔
 */
export function isSpirit(devil: Devil) {
  return devil.basicData.type === "精霊";
}

/**
 * 御霊悪魔か判別する
 * @param devil 判別対象の悪魔
 */
export function isMitama(devil: Devil) {
  return devil.basicData.type === "御霊";
}

/**
 * 合体結果のコンテンツの色識別子を判別する（通常：result、精霊：spirit-up or spirit-down）
 * @param rds 合体結果
 */
export function calcColorPattern(rds: ResultDevilSet): string {
  const _isSpirit: boolean = getStandardFusionPattern(
    rds.devil1, rds.devil2
  ) === standardFusionPattern.SPIRIT;

  if (!_isSpirit || rds.resultDevil === noResultForFusion) {
    return "result";
  } else {
    const key: string = isSpirit(rds.devil1)
      ? "devil1"
      : "devil2";
    return rds[key].basicData.level < rds.resultDevil.basicData.level
      ? "spirit-up"
      : "spirit-down";
  }
}

/**
 * 特殊合体のデータを取得する
 * @param series シリーズ情報　
 */
export function specialPatternList(series: string): string[] {
  const list: string[] = rm.importJsonForPatternOfSpecial(series).map(
    data => {
      return data.resultBasicData.name;
    }
  );
  return list;
}

/**
 * M3用特殊合体の結果を算出する
 * @param name 特殊合体で作成する悪魔名
 */
export function specialPatternResult(
  name: string
): SpecialResultDevilSetForM3[] {
  const _series: string = "m_3";
  const devil: SpecialFusionForM3 = rm.importJsonForPatternOfSpecial(_series).find(
    value => {
      return value.resultBasicData.name === name
    }
  );
  const result: SpecialResultDevilSetForM3[] = [];
  // 1.アマテラス、2.シヴァ、3.オンギョウキ、8.ミカエル、9.ガブリエル、10.ラファエル
  if (devil.srcDevil.ptn === "FIX") {
    const devil1: Devil = convertRaceName(
      getDevilInfoById(
        devil.srcDevil.devil1.id ? devil.srcDevil.devil1.id : 1,
        _series
      ),
      _series
    );
    const devil2: Devil = convertRaceName(
      getDevilInfoById(
        devil.srcDevil.devil2 && devil.srcDevil.devil2.id
          ? devil.srcDevil.devil2.id : 1,
        _series
      ),
      _series
    );
    if (devil.sacrifice.ptn === "FIX") {
      result.push({
        devil1,
        devil2,
        sacrifice: convertRaceName(
          getDevilInfoById(
            devil.sacrifice.data && devil.sacrifice.data.id
              ? devil.sacrifice.data.id : 1,
            _series
          ),
          _series
        )
      })
    }
    else if (devil.sacrifice.ptn === "NONE") {
      result.push({ devil1, devil2 });
    }
  }
  // 4.サマエル、5.ギリメカラ、11.グルル
  else if (devil.srcDevil.ptn === "CALC_FIX") {
    const resultDevilSet: ResultDevilSet[] = getReverseResult(
      convertRaceName(
        getDevilInfoByName(
          devil.srcDevil.devil1.name
            ? devil.srcDevil.devil1.name
            : '',
          _series
        ),
        _series
      ), _series, false
    );
    if (devil.sacrifice.ptn === "ANY") {
      const sacrificeList: Devil[] = getFilterDevilList(
        getRaceInfoByType(
          devil.sacrifice.data && devil.sacrifice.data.type
            ? devil.sacrifice.data.type
            : "",
          _series
        ).basicData.name,
        _series
      );
      resultDevilSet.forEach(rds => {
        if (
          rds.devil1.basicData.name !== name &&
          rds.devil2.basicData.name !== name
        ) {
          sacrificeList.forEach(sac => {
            result.push({
              devil1: rds.devil1,
              devil2: rds.devil2,
              sacrifice: convertRaceName(sac, _series)
            });
          });
        }
      });
    }
    else if (devil.sacrifice.ptn === "NONE") {
      resultDevilSet.forEach(rds => {
        result.push({
          devil1: rds.devil1,
          devil2: rds.devil2,
        });
      });
    }
  }
  // 7.メタトロン
  else if (devil.srcDevil.ptn === "HALF_FIX") {
    const devil1: Devil = convertRaceName(
      getDevilInfoById(
        devil.srcDevil.devil1.id ? devil.srcDevil.devil1.id : 1,
        _series
      ),
      _series
    );
    const typeList: string[] =
      devil.srcDevil.devil2 && devil.srcDevil.devil2.type
        ? devil.srcDevil.devil2.type
        : [];
    const devil2List: Devil[] = [];
    typeList.forEach(type => {
      const list: Devil[] = getFilterDevilList(
        getRaceInfoByType(type, _series).basicData.name, _series
      );
      list.forEach(devil => devil2List.push(devil));
    });
    if (devil.sacrifice.ptn === "ANY") {
      const sacrificeList: Devil[] = getFilterDevilList(
        getRaceInfoByType(
          devil.sacrifice.data && devil.sacrifice.data.type
            ? devil.sacrifice.data.type
            : "",
          _series
        ).basicData.name,
        _series
      );
      devil2List.forEach(_devil2 => {
        if (_devil2.basicData.name !== name) {
          const devil2 = convertRaceName(_devil2, _series);
          sacrificeList.forEach(sac => {
            result.push({
              devil1,
              devil2,
              sacrifice: convertRaceName(sac, _series)
            })
          });
        }
      });
    }
  }
  // 6.サカハギ
  else if (devil.srcDevil.ptn === "CALC_ANY") {
    const list: Devil[] = getFilterDevilList(
      getRaceInfoByType(
        devil.srcDevil.devil1.type ? devil.srcDevil.devil1.type : "", _series
      ).basicData.name,
      _series
    );
    let resultDevilSet: ResultDevilSet[] = [];
    list.forEach(_devil => {
      resultDevilSet = resultDevilSet.concat(
        getReverseResult(
          convertRaceName(_devil, _series),
          _series,
          true
        )
      )
    });
    resultDevilSet.forEach(rds => result.push({
      devil1: rds.devil1,
      devil2: rds.devil2
    }));
  }

  return result;
}

/**
 * 合体結果を魔人合体の合体結果に変換する
 * @param resultDevilSet 合体結果
 */
export function majinConverter(
  resultDevilSet: ResultDevilSet[]
): ResultDevilSet[] {
  const convertList: ResultDevilSet[] = [];

  resultDevilSet.forEach(data => {
    getMajinResult(data.resultDevil).forEach(result => {
      getMoonAge(result).forEach(r => {
        const rd: Devil = convertRaceName(result, "m_3");
        convertList.push({
          devil1: data.devil1,
          devil2: data.devil2,
          resultDevil: {
            id: rd.id,
            attribute: rd.attribute,
            basicData: rd.basicData,
            moonAge: r
          }
        })
      })
    })
  });

  return convertList;
}

/**
 * 対象悪魔の種族から作成可能な魔人情報を得る
 * @param devil 魔人合体元の悪魔情報
 */
export function getMajinResult(
  devil: Devil
): Devil[] {
  switch (devil.basicData.type) {
    case "妖魔":
      return [
        getDevilInfoByName("ホワイトライダー", "m_3"),
        getDevilInfoByName("マタドール", "m_3")
      ];
    case "妖精":
      return [
        getDevilInfoByName("レッドライダー", "m_3"),
        getDevilInfoByName("ヘルズエンジェル", "m_3")
      ];
    case "夜魔":
      return [
        getDevilInfoByName("ブラックライダー", "m_3"),
        getDevilInfoByName("だいそうじょう", "m_3")
      ];
    case "魔王":
      return [
        getDevilInfoByName("ペイルライダー", "m_3"),
        getDevilInfoByName("マザーハーロット", "m_3"),
        getDevilInfoByName("トランペッター", "m_3")
      ];
    default:
      return [];
  }
}

/**
 * 魔人合体ごとの月齢情報を得る
 * @param devil 魔人悪魔情報
 */
export function getMoonAge(
  devil: Devil
): string[] {
  switch (devil.basicData.name) {
    case "ホワイトライダー":
      return ["SILENT"];
    case "レッドライダー":
      return ["SILENT"];
    case "ブラックライダー":
      return ["SILENT"];
    case "ペイルライダー":
      return ["SILENT"];
    case "マタドール":
      return ["1/8", "2/8", "3/8", "HALF"];
    case "ヘルズエンジェル":
      return ["HALF", "5/8", "6/8", "7/8"];
    case "だいそうじょう":
      return ["6/8", "7/8", "FULL"];
    case "マザーハーロット":
      return ["HALF"];
    case "トランペッター":
      return ["FULL"];
    default:
      return [];
  }
}

/**
 * 魔人合体の場合に合体結果魔人に成りうる種族情報を得る
 * @param devil 魔人悪魔情報
 */
export function getReversePatternForMajin(
  devil: Devil
): string {
  switch (devil.basicData.name) {
    case "ホワイトライダー":
      return "妖魔";
    case "レッドライダー":
      return "妖精";
    case "ブラックライダー":
      return "夜魔";
    case "ペイルライダー":
      return "魔王";
    case "マタドール":
      return "妖魔";
    case "ヘルズエンジェル":
      return "妖精";
    case "だいそうじょう":
      return "夜魔";
    case "マザーハーロット":
      return "魔王";
    case "トランペッター":
      return "魔王";
    default:
      return "";
  }
}

/**
 * 対象の悪魔がM3の魔人かどうか判別する
 * @param devil 判別対象の悪魔
 */
export function isMajinForM3(
  devil: Devil
): boolean {
  const ids: number[] = getFilterDevilList("魔人", "m_3").map(d => {
    return d.id
  });
  return ids.includes(devil.id);
}
