import React from 'react';
import './Sort.scss';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { selectDevil, selectData, selectTurn } from '../../ducks/model';
import { useDispatch, connect } from "react-redux";
import { ActionType } from '../../ducks/actions'
import { LoggingManager as logger } from '../../common/CommonLogger';

// modal style
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '1px solid #fff',
    boxShadow: '1px 3px 5px -1px rgba(255,255,255,0.2), 0px 5px 8px 0px rgba(255,255,255,0.2), 0px 1px 14px 0px rgba(255,255,255,0.2)',
    padding: theme.spacing(2, 4),
    width: '80%',
  },
}));

// radio style
const YellowRadio = withStyles({
  root: {
    color: yellow[400],
    '&$checked': {
      color: yellow[600],
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

function SortMain(props) {

  const sortIcon = require('../../assets/img/sort_icon.png');

  // modal setting
  const classes = useStyles();
  const open = props.open;

  // radio setting
  const selectedValueDevil = props.selectedDevil;
  const selectedValueData = props.selectedData;
  const selectedValueTurn = props.selectedTurn;

  logger(["sort props", props]);
  logger(["sort param", selectedValueDevil, selectedValueData, selectedValueTurn]);

  // dispatch
  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch({ type: ActionType.MODAL_OPEN, b: true });
  };
  const handleClose = () => {
    dispatch({ type: ActionType.MODAL_CLOSE, b: false });
  };
  const handleChangeDevil = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.SELECTED_DEVIL, event: event.target.value });
  };
  const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.SELECTED_DATA, event: event.target.value });
  };
  const handleChangeTurn = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.SELECTED_TURN, event: event.target.value });
  };

  return (
    <div className="sort-wrapper">
      <div className="sort" onClick={handleOpen}>
        <img src={sortIcon} alt="sort" />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper + " modal-wrapper"}>
            <h2 id="transition-modal-title" className="modal-title">表示順変更</h2>
            <div id="transition-modal-description" className="modal-main">
              <div className="sort-content devil">
                {Object.keys(selectDevil).map(i => (
                  <FormControlLabel key={i} value={selectDevil[i].key} control={
                    <YellowRadio
                      checked={selectedValueDevil === selectDevil[i].key}
                      onChange={handleChangeDevil}
                      value={selectDevil[i].key}
                      name="radio-button-devil"
                      // inputProps={{ 'aria-label': 'E' }}
                      size="small"
                    />} label={selectDevil[i].value} />
                ))}
              </div>
              <div className="sort-content data">
                {Object.keys(selectData).map(i => (
                  <FormControlLabel key={i} value={selectData[i].key} control={
                    <YellowRadio
                      checked={selectedValueData === selectData[i].key}
                      onChange={handleChangeData}
                      value={selectData[i].key}
                      name="radio-button-data"
                      // inputProps={{ 'aria-label': 'E' }}
                      size="small"
                    />} label={selectData[i].value} />
                ))}
              </div>
              <div className="sort-content turn">
                {Object.keys(selectTurn).map(i => (
                  <FormControlLabel key={i} value={selectTurn[i].key} control={
                    <YellowRadio
                      checked={selectedValueTurn === selectTurn[i].key}
                      onChange={handleChangeTurn}
                      value={selectTurn[i].key}
                      name="radio-button-turn"
                      // inputProps={{ 'aria-label': 'E' }}
                      size="small"
                    />} label={selectTurn[i].value} />
                ))}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );

}

function mapStateProps(state) {
  return {
    open: state.sortReducer.open,
    selectedDevil: state.sortReducer.selectedDevil,
    selectedData: state.sortReducer.selectedData,
    selectedTurn: state.sortReducer.selectedTurn,
  };
}

export const Sort = React.memo(
  connect(
    mapStateProps,
    null,
    null,
    {
      areStatePropsEqual(next, prev) {
        logger(["Sort state compare next:", next]);
        logger(["Sort state compare prev:", prev]);
        return (
          next.open === prev.open &&
          next.selectedDevil === prev.selectedDevil &&
          next.selectedData === prev.selectedData &&
          next.selectedTurn === prev.selectedTurn
        );
      }
    }
  )(SortMain));