import {
  ResultDevilSet,
  selectDevil,
  selectData,
  selectTurn,
  initialDevil,
} from '../ducks/model';
import { LoggingManager as logger } from '../common/CommonLogger';

type sortProps = {
  selectedDevil: string;
  selectedData: string;
  selectedTurn: string;
}

const excludePattern: ResultDevilSet[] = [{
  devil1: initialDevil,
  devil2: initialDevil,
  resultDevil: initialDevil,
}];

const excludePatternDetail = {
  devil1: {
    key: 'devil1',
    pattern: [1, 2, 3]
  },
  devil2: {
    key: 'devil2',
    pattern: [4, 5, 6]
  },
  resultDevil: {
    key: 'resultDevil',
    pattern: [7, 8, 9]
  }
}

let sortTurns: number = 1;
let sortKeyPtn: number = 0;

export function sortMain(resultDevilSet: ResultDevilSet[], sortParam: sortProps, fixColumn: string): ResultDevilSet[] {
  // sort 対象が undefined の場合、処理しない
  if (typeof resultDevilSet === 'undefined') return [];
  // sort 対象が 全て初期値の場合、処理しない
  if (resultDevilSet === excludePattern) return [];

  // sortParam から sortパターンを算出 
  const { selectedDevil, selectedData, selectedTurn } = sortParam;
  const sortTargetIdx = getSortIndex(selectedDevil);
  const sortFactorIdx = getSortIndex(selectedData);
  sortKeyPtn = (sortTargetIdx - 1) * 3 + sortFactorIdx;
  sortTurns = getSortIndex(selectedTurn) === 1 ? -1 : 1;

  logger(['sortMain sortKeyPtn', sortKeyPtn]);
  logger(['sortMain sortTurns', sortTurns]);

  // sort対象の列が全て同じ値の場合は、sort不要のため、処理しない
  if (isExcludePatternDetail(fixColumn)) {
    logger(['sortMain', sortKeyPtn, fixColumn]);
    return resultDevilSet;
  }

  // sort結果の返却
  return resultDevilSet.sort(compareKey);
}

/**
 * compareKey
 * 
 * @param x - 比較対象のDevilデータ1
 * @param y - 比較対象のDevilデータ2
 */
function compareKey(x: ResultDevilSet, y: ResultDevilSet): number {
  switch (sortKeyPtn) {
    // Devil1 のレベルの場合
    case 1:
      return sortTurns * (x.devil1.basicData.level < y.devil1.basicData.level ? -1 : 1);
    // Devil1 の属性の場合
    case 2:
      return sortTurns * x.devil1.attribute.localeCompare(y.devil1.attribute, 'ja');
    // Devil1 の名前の場合
    case 3:
      return sortTurns * x.devil1.basicData.name.localeCompare(y.devil1.basicData.name, 'ja');
    // Devil2 のレベルの場合
    case 4:
      return sortTurns * (x.devil2.basicData.level < y.devil2.basicData.level ? -1 : 1);
    // Devil2 の属性の場合
    case 5:
      return sortTurns * x.devil2.attribute.localeCompare(y.devil2.attribute, 'ja');
    // Devil2 の名前の場合
    case 6:
      return sortTurns * x.devil2.basicData.name.localeCompare(y.devil2.basicData.name, 'ja');
    // resultDevil のレベルの場合
    case 7:
      return sortTurns * (x.resultDevil.basicData.level < y.resultDevil.basicData.level ? -1 : 1);
    // resultDevil の属性の場合
    case 8:
      return sortTurns * x.resultDevil.attribute.localeCompare(y.resultDevil.attribute, 'ja');
    // resultDevil の名前の場合
    case 9:
      return sortTurns * x.resultDevil.basicData.name.localeCompare(y.resultDevil.basicData.name, 'ja');
    // いずれにも該当しない場合
    default:
      return 1;
  }
}

/**
 * isExcludePatternDetail
 *   - fixColumn(全ての列の値が同じ)と excludePatternDetail の持っている値を照合し、
 *     sortKeyPtnが含まれている場合は、true を返す
 * 
 * @param fixColumn - 値が全て同じ列のキー名（devil1, devil2, resuktDevil）
 * @returns true/false  - true: sort不要なので処理を中断する、false: sortが必要
 */
function isExcludePatternDetail(fixColumn: string): boolean {
  switch (fixColumn) {
    case excludePatternDetail.devil1.key:
      return excludePatternDetail.devil1.pattern.includes(sortKeyPtn);
    case excludePatternDetail.devil2.key:
      return excludePatternDetail.devil2.pattern.includes(sortKeyPtn);
    case excludePatternDetail.resultDevil.key:
      return excludePatternDetail.resultDevil.pattern.includes(sortKeyPtn);
    default:
      return false;
  }
}

/**
 * getSortIndex
 *   - keyword が selectDevil or selectData or selectTurn の何番目の定義に該当するか判断する
 *   - 該当した要素番号を返す
 * 
 * @param keyword - selectDevil or selectData or selectTurn のいずれかのキーに該当する文字列
 * @returns 要素番号 - keyword の selectDevil or selectData 内での出現位置
 */
function getSortIndex(keyword: string): number {
  const consts = [selectDevil, selectData, selectTurn];
  let j = 0;
  consts.forEach(c => {
    let idx = 0;
    const constValues = getConstKeys(c);
    constValues.forEach((key, i) => {
      idx = idx + 1;
      if (key === keyword) {
        j = idx;
      }
    });
  });
  return j;
}

/**
 * getConstKeys
 *   - 引数で取得した定数データについて、keyのみの配列を作成し返却する
 * 
 * @param constData - key, value 型の定数データ
 * @returns 引数で取得したデータの、keyのみの配列データ
 */
function getConstKeys(constData: { key: string; value: string }[]): string[] {
  return constData.map(data => {
    return data.key;
  });
}
