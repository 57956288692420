import React from 'react';
import DevilFusion from '../../components/DevilFusionResult/DevilFusionResult'
import {
  selectedDevilSelector,
  selectedDataSelector,
  selectedTurnSelector,
} from '../../ducks/selector';
import { connect } from 'react-redux';
import { ResultDevilSet } from '../../ducks/model';
import { sortMain } from '../../common/SortResult';
import { LoggingManager as logger } from '../../common/CommonLogger';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import './FusionResult.scss';

/**
 * FusionResultMain
 *   - 合体結果の表示メインコンポーネント
 *   - 合体結果に対してsortを実行する
 * 
 * @param props - mapStateProps
 */
const FusionResultMain = (props) => {

  // props から情報抽出
  const {
    resultDevilSet,
    selectedDevil,
    selectedData,
    selectedTurn,
    displayPtn,
    fixColumn,
    series
  } = props;
  logger(['FusionResult', props]);

  // sort の実行
  const displayResults: ResultDevilSet[] = sortMain(
    resultDevilSet,
    {
      selectedDevil,
      selectedData,
      selectedTurn,
    },
    fixColumn
  );

  logger(['displayResults', displayResults]);
  logger(['displayResults', displayResults.length]);

  return (
    <div>
      {(displayResults.length === 0) &&
        <div className="no-result-wrapper">
          <div className="display-no-result">
            合体結果はありません
          </div>
          <MoodBadIcon />
        </div>
      }
      {Object.keys(displayResults).map(i => (
        <DevilFusion
          key={i}
          resultSet={displayResults[i]}
          displayPtn={displayPtn}
          series={series}
        />
      ))}
    </div>
  );
}

/**
 * mapStateProps
 *   - state から props を設定する
 * 
 * @param state - state
 */
function mapStateProps(state) {
  return {
    selectedDevil: selectedDevilSelector(state),
    selectedData: selectedDataSelector(state),
    selectedTurn: selectedTurnSelector(state),
  }
}

/**
 * FusionResult
 *   - memo化した FusionResultMain
 */
export const FusionResult = React.memo(connect(mapStateProps)(FusionResultMain));
