import React from 'react';
import './TopPage.scss';
import { useHistory } from 'react-router-dom';
import { getBasePath } from '../../common/PathManager';

function TopPage() {

  const topPageIcon = require('../../assets/img/home_icon.png');
  const history = useHistory();
  const topPage = () => {
    history.push(getBasePath());
  };

  return (
    <div className="top-page" onClick={topPage}>
      <img src={topPageIcon} alt="top-page" />
    </div>
  );

}

export default TopPage;