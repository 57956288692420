import React, { useCallback, useState } from 'react';
import DevilContent from '../../components/DevilContent/DevilContent';
import './DevilList.scss';
import { Devil, Race } from '../../ducks/model';
import { convertRaceName } from '../../common/CalcResult';
import * as rm from '../../common/ResourceManager';
import { Filter } from '../../components/Filter/Filter';
import { filterList } from '../../common/DataSelector';

function DevilListMain(props) {

  const { series } = props;
  const [devilsData, setDevilsData] = useState<Devil[]>(
    (rm.importJsonForDevils(series) as Devil[]).map(element => {
      return convertRaceName(element, series)
    })
  );
  const [filterRaceList1, setFilterRaceList1] = useState<string[]>(
    (rm.importJsonForRaces(series) as Race[]).map(element => {
      return element.basicData.name
    })
  );
  const [filterDevilList1, setFilterDevilList1] = useState<string[]>(
    (rm.importJsonForDevils(series) as Devil[]).map(element => {
      return element.basicData.name
    })
  );

  const listReset = useCallback(
    (filterList: Devil[]) => {
      const raceList: string[] = [];
      filterList.forEach(element => {
        !raceList.includes(element.basicData.type)
          && raceList.push(element.basicData.type)
      })
      setFilterRaceList1(raceList);
      setFilterDevilList1(filterList.map(element => {
        return element.basicData.name
      }));
      setDevilsData(filterList);
    }, [setFilterRaceList1, setFilterDevilList1, setDevilsData]
  );

  const changeRaceList1 = useCallback(
    (race: string) => {
      const _filterList: Devil[] = filterList(devilsData, "type", race);
      listReset(_filterList);
    }, [listReset, devilsData]
  );
  const changeDevilList1 = useCallback(
    (devil: string) => {
      const _filterList: Devil[] = filterList(devilsData, "name", devil);
      listReset(_filterList);
    }, [listReset, devilsData]
  );

  const filterClear = useCallback(() => {
    listReset(
      (rm.importJsonForDevils(series) as Devil[]).map(element => {
        return convertRaceName(element, series)
      })
    );
  }, [listReset, series]);

  return (
    <div className="devil-list-wrapper">
      <Filter
        series={series}
        filterRaceList1={filterRaceList1}
        filterDevilList1={filterDevilList1}
        changeRaceList1={changeRaceList1}
        changeDevilList1={changeDevilList1}
        filterClear={filterClear}
      />
      <div className="main-wrapper">
        <div className="devil-list">
          {Object.keys(devilsData).map(i => (
            childContent(i, devilsData[i], series)
          ))}
        </div>
      </div>
    </div>
  );
}

function childContent(index: string, devilData: Devil, series: string) {
  return (
    <div key={index} className="list-child">
      <DevilContent
        devil={convertRaceName(devilData, series)}
        series={series}
      />
    </div>
  );
}


export const DevilList = React.memo(DevilListMain);


