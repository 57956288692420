import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import {
  conditionReducer,
  resultReducer,
  choiceReducer,
  sortReducer,
  accordionReducer,
} from './ducks/reducer';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { createStore, combineReducers } from 'redux';
import { RoutingManager } from './components/RoutingManager/RoutingManager';

const reducers = combineReducers({
  conditionReducer,
  resultReducer,
  choiceReducer,
  sortReducer,
  accordionReducer,
});

const rootReducer = (state, action) => {
  const reduce = reducers(state, action);
  return reduce;
}
const store = createStore(rootReducer);
// const { promiseInProgress } = usePromiseTracker();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Provider store={store}>
          <RoutingManager></RoutingManager>
        </Provider>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// function Index() {
//   return(
//     <Footer></Footer>
//   )
// }
