import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { calcNumberOfDigits, zeroPadding } from './NumberFunction';
import * as rm from '../common/ResourceManager';
import { Devil, Race } from '../ducks/model';

type OptProps = {
  code: string;
  label: string;
}
type SuggestProps = {
  flg: boolean;
  series: string | null;
  pattern: "race" | "devil" | null
}
type Props = {
  id: string;
  value: string | null;
  options: string[];
  onChange: (value: string | null) => void;
  placeholder?: string;
  sortFlg?: boolean;
  addSuggestFlg?: SuggestProps;
};

export const ComboBoxManager = (props: Props) => {
  const { id, value, options, onChange, placeholder, sortFlg, addSuggestFlg } = props;
  const __options: string[] = sortFlg ? sortItems(options) : options;
  const _options: OptProps[] = processingItems(__options, addSuggestFlg);
  const _placeholder: string = placeholder ? placeholder : '';
  return (
    <Autocomplete
      id={id}
      value={value
        ? _options.find(data => {
          return data.label === value
            ? { code: data.code, label: data.label }
            : { code: "", label: value }
        })
        : null
      }
      options={_options}
      getOptionLabel={option => option.code + option.label}
      onChange={(event: any, value: OptProps | null) => {
        value ? onChange(value.label) : onChange(null)
      }}
      renderOption={(option) => (
        <React.Fragment>
          {option.code}{option.label}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <div ref={params.InputProps.ref} className="combo-wrapper">
          <input
            type="text" {...params.inputProps}
            className="combo"
            placeholder={_placeholder}
          />
        </div>
      )}
    />
  );
}

const processingItems = (options: string[], addSuggestFlg?: SuggestProps) => {
  const _options: OptProps[] = [];
  if (addSuggestFlg) {
    switch (addSuggestFlg.pattern) {
      case "race":
        options.forEach(data => {
          const races = rm.importJsonForRaces(addSuggestFlg.series ? addSuggestFlg.series : "");
          const _data: Race = races.find(element => {
            return data === element.basicData.name;
          });
          _options.push({
            code: zeroPadding(_data.id, calcNumberOfDigits(races.length)) + ": ",
            label: _data.basicData.name
          });
        })
        break;
      case "devil":
        options.forEach(data => {
          const devils = rm.importJsonForDevils(addSuggestFlg.series ? addSuggestFlg.series : "");
          const _data: Devil = devils.find(element => {
            return data === element.basicData.name;
          });
          _options.push({
            code: zeroPadding(_data.id, calcNumberOfDigits(options.length)) + ": ",
            label: _data.basicData.name
          });
        })
        break;
      default:
        for (let index = 0; index < options.length; index++) {
          const element = options[index];
          _options.push({
            code: addSuggestFlg.flg
              ? zeroPadding(index + 1, calcNumberOfDigits(options.length)) + ": "
              : "",
            label: element
          });
        }
        break;
    }
  } else {
    options.forEach(element => {
      _options.push({
        code: "", label: element
      });
    });
  }
  return _options;
}

const sortItems = (options: string[]) => {
  return options.sort((a, b) => a.localeCompare(b, 'ja'));
}

