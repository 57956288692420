import React, { useCallback, useState } from 'react';
import { ActionType } from '../../ducks/actions'
import './DevilFusion.scss';
import {
  useDispatch,
  connect
} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  devil1Selector,
  devil2Selector,
  curseSelector, majinSelector
} from '../../ducks/selector';
import * as rm from '../../common/ResourceManager';
import { LoggingManager as logger } from '../../common/CommonLogger';
import { getFilterDevilList, getInitialDataForDevilList } from '../../common/DataSelector';
import { FormGroup, Grid, Theme, Typography, withStyles, createStyles, Switch } from '@material-ui/core';

// switch style
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

const App = (props) => {

  const { devil1, devil2, series, majin, curse } = props;
  const raceList1 = rm.importJsonForRaces(series).map(
    data => {
      return data.basicData.name;
    }
  );
  const raceList2 = rm.importJsonForRaces(series).map(
    data => {
      return data.basicData.name;
    }
  );
  const [devilList1, setDevilList1] = useState(
    getInitialDataForDevilList().concat(
      rm.importJsonForDevils(series).map(
        data => {
          return data.basicData.name;
        }
      )
    )
  );
  const [devilList2, setDevilList2] = useState(
    getInitialDataForDevilList().concat(
      rm.importJsonForDevils(series).map(
        data => {
          return data.basicData.name;
        }
      )
    )
  );

  logger(["devil1", devil1]);
  logger(["devil2", devil2]);

  const dispatch = useDispatch();

  const onChangeRaceList1 = useCallback(
    (race: string | null) => {
      if (race) {
        setDevilList1(getInitialDataForDevilList().concat(
          getFilterDevilList(race, series).map(
            data => {
              return data.basicData.name
            }
          ))
        );
        dispatch({ type: ActionType.RACE1_CHANGE, race1: race, series: series });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [series]
  );
  const onChangeRaceList2 = useCallback(
    (race: string | null) => {
      if (race) {
        setDevilList2(getInitialDataForDevilList().concat(
          getFilterDevilList(race, series).map(
            data => {
              return data.basicData.name
            }
          ))
        );
        dispatch({ type: ActionType.RACE2_CHANGE, race2: race, series: series });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [series]
  );
  const onChangeDevilList1 = useCallback(
    (devil: string | null) => {
      if (devil) {
        dispatch({ type: ActionType.DEVIL1_CHANGE, devil1: devil, series: series });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [series]
  );
  const onChangeDevilList2 = useCallback(
    (devil: string | null) => {
      if (devil) {
        dispatch({ type: ActionType.DEVIL2_CHANGE, devil2: devil, series: series });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [series]
  );
  const majinCheckChange = useCallback(
    () => {
      dispatch({ type: ActionType.MAJIN_CHECK_CHANGE, majin: !majin });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [majin]
  );
  const curseCheckChange = useCallback(
    () => {
      dispatch({ type: ActionType.CURSE_CHECK_CHANGE, curse: !curse });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curse]
  );

  return (
    <div className="devil-fusion-wrapper">
      <div className="select-devil-wrapper">
        <div className="select-devil devil1">
          <Autocomplete
            id="fusion-combo-devil1-type"
            value={devil1.basicData.type}
            options={raceList1}
            onChange={(event: any, value: string | null) => { onChangeRaceList1(value) }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref} className="combo-wrapper">
                <input type="text" {...params.inputProps} className="combo" placeholder="種族1" />
              </div>
            )}
          />
          <Autocomplete
            id="fusion-combo-devil1-name"
            value={devil1.basicData.name}
            options={devilList1}
            onChange={(event: any, value: string | null) => { onChangeDevilList1(value) }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref} className="combo-wrapper">
                <input type="text" {...params.inputProps} className="combo" placeholder="悪魔1" />
              </div>
            )}
          />
        </div>
        <div className="fusion-icon add">＋</div>
        <div className="select-devil devil2">
          <Autocomplete
            id="fusion-combo-devil2-type"
            value={devil2.basicData.type}
            options={raceList2}
            onChange={(event: any, value: string | null) => { onChangeRaceList2(value) }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref} className="combo-wrapper">
                <input type="text" {...params.inputProps} className="combo" placeholder="種族2" />
              </div>
            )}
          />
          <Autocomplete
            id="fusion-combo-devil2-name"
            value={devil2.basicData.name}
            options={devilList2}
            onChange={(event: any, value: string | null) => { onChangeDevilList2(value) }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref} className="combo-wrapper">
                <input type="text" {...params.inputProps} className="combo" placeholder="悪魔2" />
              </div>
            )}
          />
        </div>
      </div>
      {series === 'm_3' &&
        <div className="check-box">
          <div className="option-contents">
            <FormGroup className="option">
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item className="option-name">魔人合体</Grid>
                  <Grid item>
                    <AntSwitch checked={majin} onChange={() => majinCheckChange()} name="checked" />
                  </Grid>
                </Grid>
              </Typography>
            </FormGroup>
            <FormGroup className="option under">
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item className="option-name">呪い合体</Grid>
                  <Grid item>
                    <AntSwitch checked={curse} onChange={() => curseCheckChange()} name="checked" />
                  </Grid>
                </Grid>
              </Typography>
            </FormGroup>
          </div>
        </div>
      }
    </div>
  );
}

/**
 * state を props にマッピングする
 * （マッピングに記載の state が変化した時、このコンポーネントを再レンダリングする）
 * 
 * @param state 
 */
function mapStateProps(state) {
  return {
    devil1: devil1Selector(state),
    devil2: devil2Selector(state),
    majin: majinSelector(state),
    curse: curseSelector(state)
  };
}

export const MemoizedApp = React.memo(connect(mapStateProps)(App));
// export const MemoizedApp = React.memo(App);



