import React from 'react';
import {
  Route,
} from 'react-router-dom';
import { getBasePath } from '../../common/PathManager';
import { MemoizedApp } from '../../pages/DevilFusion/DevilFusion';
import { DevilList } from '../../pages/DevilList/DevilList';
import { ReverseFusion } from '../../pages/ReverseFusion/ReverseFusion';
import { SpecialFusionWrapper } from '../../pages/SpecialFusion/SpecialFusionWrapper';
import TopPage from '../../pages/TopPage/TopPage';
import Footer from '../Footer/Footer';
import { MemoizedFusionWrapper } from '../FusionWrapper/FusionWrapper';
import Header from '../Header/Header';


const RoutingManagerMain = () => {
  return (
    <>
      <Route path={getBasePath()} exact>
        <Header title="悪魔の世界へようこそ" sort="false"></Header>
        <div className="main toppage">
          <TopPage />
        </div>
      </Route>
      <Route path={getBasePath() + "m-3/devil-fusion"} exact>
        <Header title="二身合体"></Header>
        <div className="main">
          <MemoizedApp series={"m_3"} />
          <MemoizedFusionWrapper series={"m_3"} />
        </div>
        <Footer series={"m_3"} ></Footer>
      </Route>
      <Route path={getBasePath() + "m-3/devil-list"} exact>
        <Header title="悪魔一覧" sort="false"></Header>
        <div className="main">
          <DevilList series={"m_3"} />
        </div>
        <Footer series={"m_3"} ></Footer>
      </Route>
      <Route path={getBasePath() + "m-3/reverse-fusion"} exact>
        <Header title="合体逆引き"></Header>
        <div className="main">
          <ReverseFusion series={"m_3"} />
        </div>
        <Footer series={"m_3"} ></Footer>
      </Route>
      <Route path={getBasePath() + "m-3/special-fusion"} exact>
        <Header title="特殊合体" sort="false"></Header>
        <div className="main">
          <SpecialFusionWrapper series={"m_3"} />
        </div>
        <Footer series={"m_3"} ></Footer>
      </Route>
      <Route path={getBasePath() + "m-dsj/devil-fusion"} exact>
        <Header title="二身合体"></Header>
        <div className="main">
          <MemoizedApp series={"m_dsj"} />
          <MemoizedFusionWrapper series={"m_dsj"} />
        </div>
        <Footer series={"m_dsj"} ></Footer>
      </Route>
      <Route path={getBasePath() + "m-dsj/devil-list"} exact>
        <Header title="悪魔一覧" sort="false"></Header>
        <div className="main">
          <DevilList series={"m_dsj"} />
        </div>
        <Footer series={"m_dsj"} ></Footer>
      </Route>
      <Route path={getBasePath() + "m-dsj/reverse-fusion"} exact>
        <Header title="合体逆引き"></Header>
        <div className="main">
          <ReverseFusion series={"m_dsj"} />
        </div>
        <Footer series={"m_dsj"} ></Footer>
      </Route>
      <Route path={getBasePath() + "m-dsj/special-fusion"} exact>
        <Header title="特殊合体" sort="false"></Header>
        <div className="main">
          <SpecialFusionWrapper series={"m_dsj"} />
        </div>
        <Footer series={"m_dsj"} ></Footer>
      </Route>
    </>
  );
}

export const RoutingManager = RoutingManagerMain;
