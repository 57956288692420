import { getFilterDevilList } from '../../common/DataSelector'
import { getResultForSpiritXSpirit, convertRaceName } from '../../common/CalcResult'
import {
  Devil,
  ResultDevilSet,
  noResultForFusion,
  unselected
} from '../../ducks/model';

const SpiritXSpiritFusion = (
  devil1: Devil,
  devil2: Devil,
  series: string,
  curse?: boolean
) => {

  // 精霊のリスト
  const spiritDevilList = getFilterDevilList(devil1.basicData.type, series);

  let resultDevilSet: ResultDevilSet[] = [{
    devil1: devil1,
    devil2: devil2,
    resultDevil: noResultForFusion,
  }];
  resultDevilSet.pop()

  // 悪魔１がAnyの場合
  if (devil1.basicData.name === unselected) {
    spiritDevilList.forEach((data) => {
      // 合体結果の悪魔
      const resultDevil = convertRaceName(
        getResultForSpiritXSpirit(data, devil2, series),
        series
      );
      // 合体結果をセット
      resultDevilSet.push({
        devil1: convertRaceName(data, series),
        devil2: devil2,
        resultDevil: data.basicData.name === devil2.basicData.name
          ? noResultForFusion
          : resultDevil
      });
    });
  }
  // 悪魔２がAnyの場合
  else if (devil2.basicData.name === unselected) {
    spiritDevilList.forEach((data) => {
      // 合体結果の悪魔
      const resultDevil = convertRaceName(
        getResultForSpiritXSpirit(devil1, data, series),
        series
      );
      // 合体結果をセット
      resultDevilSet.push({
        devil1: devil1,
        devil2: convertRaceName(data, series),
        resultDevil: data.basicData.name === devil1.basicData.name
          ? noResultForFusion
          : resultDevil
      });
    });
  }
  // 悪魔１・２とも固定の場合
  else {
    // 合体結果の悪魔
    const resultDevil = convertRaceName(
      getResultForSpiritXSpirit(devil1, devil2, series),
      series
    );
    // 合体結果をセット
    resultDevilSet = [{
      devil1: devil1,
      devil2: devil2,
      resultDevil: devil1.basicData.name === devil2.basicData.name
        ? noResultForFusion
        : resultDevil,
    }];
  }

  return resultDevilSet;
};

export default SpiritXSpiritFusion;
