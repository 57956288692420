import React from 'react';
import './SpecialFusionResult.scss';
import DevilContent from '../DevilContent/DevilContent';
import { SpecialResultDevilSet, Devil } from '../../ducks/model';
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from '../../ducks/actions'

function SpecialFusionResult(props: SpecialResultDevilSet & { series: string }) {
  const dispatch = useDispatch();
  const selectedId: number = useSelector(state => state.accordionReducer.selectedId);
  const openFlg: boolean = useSelector(state => state.accordionReducer.openFlg);
  const accordionChange = (id: number) => {
    const tmpOpenFlg = selectedId === id ? !openFlg : true;
    dispatch({ type: ActionType.ACCORDION_DEVIL, selectedId: id, openFlg: tmpOpenFlg });
  }

  return (
    <div className="devil-spec-fusion">
      <div className="result-devil" onClick={() => accordionChange(props.id)}>
        <DevilContent
          key={props.id}
          devil={props.resultDevil}
          accordion={true}
          series={props.series}
        ></DevilContent>
      </div>
      <div className={"accordion-wrapper " + (selectedId === props.id && openFlg ? "" : "hidden")}>
        <div className="spec-fusion-icon equal">=</div>
        <div className="sauce-wrapper">
          {Object.keys(props.srcDevil).map(i => (
            sauceDevilContent(i, props.srcDevil, props.series)
          ))}
        </div>
      </div>
    </div>
  );

}

export default SpecialFusionResult;

function sauceDevilContent(index: string, devilData: Devil[], series: string) {
  return (
    <div className="sauce-repeat" key={index}>
      <div className={"spec-fusion-icon add " + (index === "0" ? "first" : "")}>＋</div>
      <div className="sauce-devil">
        <DevilContent
          devil={devilData[index]}
          series={series}
        ></DevilContent>
      </div>
    </div>
  );
}