import React from 'react';
import './DevilFusionResult.scss';
import DevilContent from '../DevilContent/DevilContent';
import { ResultDisplayWrapper } from '../../ducks/model';
import { calcColorPattern } from '../../common/CalcResult';

function DevilFusionResult(props: ResultDisplayWrapper & { series: string }) {
  const { displayPtn, resultSet, series } = props;

  return (
    <div className="devil-fusion">
      <div className={"fusion-content " + (displayPtn ? "" : "reverse-src1")}>
        <DevilContent
          devil={resultSet.devil1}
          series={series}
          color={"devil1"}
        ></DevilContent>
      </div>
      <div className={"fusion-icon add " + (displayPtn ? "" : "reverse-expression")}>＋</div>
      <div className={"fusion-content " + (displayPtn ? "" : "reverse-src2")}>
        <DevilContent
          devil={resultSet.devil2}
          series={series}
          color={"devil2"}
        ></DevilContent>
      </div>
      <div className={"fusion-icon equal " + (displayPtn ? "" : "reverse-equal")}>=</div>
      <div className={"fusion-content " + (displayPtn ? "" : "reverse-result")}>
        <DevilContent
          devil={resultSet.resultDevil}
          series={series}
          color={calcColorPattern(resultSet)}
        ></DevilContent>
      </div>
    </div>
  );

}

export default DevilFusionResult;