import { LoggingManager as logger } from '../common/CommonLogger';

export function importJsonForDevils(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/devil.json');
  } catch {
    logger(['importJsonForDevil error..', importData], 'error');
    importData = require('../data/m_dsj/devil.json');
  }
  return importData;
}

export function importJsonForPatternOfSameRace(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/pattern_sameRace.json');
  } catch {
    logger(['importJsonForPatternOfSameRace error..', importData], 'error');
    importData = require('../data/m_dsj/pattern_sameRace.json');
  }
  return importData;
}

export function importJsonForPatternOfSpecial(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/pattern_special.json');
  } catch {
    logger(['importJsonForPatternOfSpecial error..', importData], 'error');
    importData = require('../data/m_dsj/pattern_special.json');
  }
  return importData;
}

export function importJsonForPatternOfSpirit(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/pattern_spirit.json');
  } catch {
    logger(['importJsonForPatternOfSpirit error..', importData], 'error');
    importData = require('../data/m_dsj/pattern_spirit.json');
  }
  return importData;
}

export function importJsonForPatternOfSpiritX(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/pattern_spiritXspirit.json');
  } catch {
    logger(['importJsonForPatternOfSpiritX error..', importData], 'error');
    importData = require('../data/m_dsj/pattern_spiritXspirit.json');
  }
  return importData;
}

export function importJsonForPatternOfStandard(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/pattern_standard.json');
  } catch {
    logger(['importJsonForPatternOfStandard error..', importData], 'error');
    importData = require('../data/m_dsj/pattern_standard.json');
  }
  return importData;
}

export function importJsonForRaces(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/race.json');
  } catch {
    logger(['importJsonForRaces error..', importData], 'error');
    importData = require('../data/m_dsj/race.json');
  }
  return importData;
}

export function importJsonForPatternOfCurse(series: string) {
  let importData;
  try {
    importData = require('../data/' + series + '/pattern_curse.json');
  } catch {
    logger(['importJsonForPatternOfCurse error..', importData], 'error');
    importData = require('../data/m_dsj/pattern_standard.json');
  }
  return importData;
}
