import React from 'react';
import SpecialFusionResult from '../../components/SpecialFusionResult/SpecialFusionResult';
import { Devil, SpecialResultDevilSet, initialDevil } from '../../ducks/model';
import { getDevilInfoByName } from '../../common/DataSelector';
import { convertRaceName } from '../../common/CalcResult';
import { choiceDevilSelector } from '../../ducks/selector';
import { connect } from 'react-redux';
import * as rm from '../../common/ResourceManager';

const SpecialFusionMain = (props) => {

  const { series } = props
  const specialFusionData = rm.importJsonForPatternOfSpecial(series);
  const fusionDatas: SpecialResultDevilSet[] = [];

  specialFusionData.forEach((value, i) => {
    let result: Devil = initialDevil;
    let source: Devil[] = [];

    result = convertRaceName(
      getDevilInfoByName(value.resultBasicData.name, series),
      series
    );
    value.srcDevil.forEach((s) => {
      source.push(
        convertRaceName(
          getDevilInfoByName(s.name, series),
          series
        )
      );
    })

    let fusionData: SpecialResultDevilSet = {
      id: i,
      resultDevil: result,
      srcDevil: source
    }
    fusionDatas.push(fusionData);

  })

  return (
    <div>
      {Object.keys(fusionDatas).map(i => (
        <SpecialFusionResult
          key={i}
          id={fusionDatas[i].id}
          resultDevil={fusionDatas[i].resultDevil}
          srcDevil={fusionDatas[i].srcDevil}
          series={series}
        ></SpecialFusionResult>
      ))}
    </div>
  );
}

/**
 * mapStateProps
 *   - state から props を設定する
 * 
 * @param state - state
 */
function mapStateProps(state) {
  return {
    choiceDevil: choiceDevilSelector(state),
  };
}

export const SpecialFusion = React.memo(
  connect(mapStateProps)(SpecialFusionMain)
);
