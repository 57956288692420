/**
 * ゼロパディング
 * @param num ゼロパディングする数値
 * @param len 桁数
 */
export function zeroPadding(num: number, len: number) {
  return (Array(len).join('0') + num).slice(-len);
}

export function calcNumberOfDigits(num: number) {
  return String(num).length;
}
