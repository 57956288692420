import React from 'react';
import './Loading.scss';

function Loading(props: { display: boolean; }) {

  return (
    <div>
      <div className={"box " + (props.display ? "" : "loaded")}>
        <div className="spinner type">
          <span>Loading...</span>
        </div>
      </div>
    </div>
  );

}

export default Loading;