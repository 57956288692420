import * as rm from '../common/ResourceManager';
import { Devil, Race } from '../ducks/model';
import { convertRaceName } from './CalcResult';

export function getInitialDevilList(series: string) {
  return getInitialDataForDevilList().concat(
    rm.importJsonForDevils(series).map(data => {
      return data.basicData.name
    })
  );
}

export function getFilterDevilList(
  race: string, series: string
): Devil[] {
  const filterType: Race = rm.importJsonForRaces(series).find(
    data => {
      return race !== null ? data.basicData.name === race : "";
    }
  );

  const tmpDevilRawData: Devil[] = rm.importJsonForDevils(series);

  return tmpDevilRawData.filter(tmpDevilRawData => {
    return tmpDevilRawData.basicData.type === filterType.basicData.type;
  });
}

export function getDevilInfoByName(
  devil: string, series: string
): Devil {
  const filterType: Devil = rm.importJsonForDevils(series).find(
    data => {
      return devil !== null ? data.basicData.name === devil : "";
    }
  );
  return filterType;
}

export function getDevilInfoById(
  id: number, series: string
): Devil {
  const filterType: Devil = rm.importJsonForDevils(series).find(
    data => {
      return id !== null ? data.id === id : "";
    }
  );
  return filterType;
}

export function getRaceInfoByType(race: string, series: string) {
  const filterType: Race = rm.importJsonForRaces(series).find(
    data => {
      return race !== null ? data.basicData.type === race : "";
    }
  );

  return filterType;
}

export function getInitialDataForDevilList() {
  return ['種族全体'];
}

export function extractRacesFromResult(
  resultList: any[],
  key: "devil1" | "devil2" | "sacrifice",
  series: string,
): string[] {
  if (resultList === []) return [];
  const list: string[] = [];

  if (Array.isArray(resultList)) {
    resultList.forEach(data => {
      data[key]
        && !list.includes(data[key].basicData.type)
        && list.push(convertRaceName(data[key], series).basicData.type)
    })
  }

  return list;
}

export function extractDevilsFromResult(
  resultList: any[],
  key: "devil1" | "devil2" | "sacrifice",
): string[] {
  if (resultList === []) return [];
  const list: string[] = [];

  if (Array.isArray(resultList)) {
    resultList.forEach(data => {
      data[key]
        && !list.includes(data[key].basicData.name)
        && list.push(data[key].basicData.name);
    });
  }

  return list;
}

export function filterResultForRace(
  resultList: any[],
  key: "devil1" | "devil2" | "sacrifice",
  raceName: string,
  series: string,
): any[] {
  const filterResultList: any[] = [];

  if (Array.isArray(resultList)) {
    resultList.forEach(data => {
      data[key]
        && convertRaceName(data[key], series).basicData.type === raceName
        && filterResultList.push(data);
    });
  }

  return filterResultList;
}

export function filterResultForDevil(
  resultList: any[],
  key: "devil1" | "devil2" | "sacrifice",
  devilName: string,
): any[] {
  const filterResultList: any[] = [];

  if (Array.isArray(resultList)) {
    resultList.forEach(data => {
      data[key]
        && data[key].basicData.name === devilName
        && filterResultList.push(data);
    });
  }

  return filterResultList;
}

export function filterList(
  devilList: Devil[],
  key: "name" | "type",
  filterValue: string,
): any[] {
  const filterList: any[] = [];

  if (Array.isArray(devilList)) {
    devilList.forEach(data => {
      data.basicData[key] === filterValue
        && filterList.push(data);
    });
  }

  return filterList;
}
