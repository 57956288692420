import { getFilterDevilList } from '../../common/DataSelector'
import { getResultForSpirit, convertRaceName } from '../../common/CalcResult'
import {
  Devil,
  ResultDevilSet,
  noResultForFusion,
  unselected
} from '../../ducks/model';

const SpiritFusion = (
  devil1: Devil, devil2: Devil, series: string, curse: boolean
) => {

  // unselectedが選択されている場合、その種族の悪魔リストを格納する
  let anyDevilList: Devil[] = [];
  // 精霊合体ロジックに渡す引数を判別する
  //   0 : resultDevilSet の初期値
  //   1 : 悪魔１が精霊、かつ、悪魔１がAny
  //   2 : 悪魔１が精霊、かつ、悪魔２がAny
  //   3 : 悪魔２が精霊、かつ、悪魔１がAny
  //   4 : 悪魔２が精霊、かつ、悪魔２がAny
  //   5 : 悪魔１が精霊、かつ、悪魔１・２ともAnyではない
  //   6 : 悪魔２が精霊、かつ、悪魔１・２ともAnyではない
  let anyPattern: number = 0;

  if (devil1.basicData.type === "精霊") {
    if (devil1.basicData.name === unselected) {
      anyDevilList = getFilterDevilList(devil1.basicData.type, series);
      anyPattern = 1;
    }
    else if (devil2.basicData.name === unselected) {
      anyDevilList = getFilterDevilList(devil2.basicData.type, series);
      anyPattern = 2;
    }
    else {
      anyPattern = 5;
    }

  } else if (devil2.basicData.type === "精霊") {
    if (devil1.basicData.name === unselected) {
      anyDevilList = getFilterDevilList(devil1.basicData.type, series);
      anyPattern = 3;
    }
    else if (devil2.basicData.name === unselected) {
      anyDevilList = getFilterDevilList(devil2.basicData.type, series);
      anyPattern = 4;
    }
    else {
      anyPattern = 6;
    }
  }

  let resultDevilSet: ResultDevilSet[] = [{
    devil1: devil1,
    devil2: devil2,
    resultDevil: noResultForFusion,
  }];
  resultDevilSet.pop();

  if (anyPattern === 1) {
    anyDevilList.forEach((data) => {
      resultDevilSet.push({
        devil1: convertRaceName(data, series),
        devil2: devil2,
        resultDevil: convertRaceName(
          getResultForSpirit(devil2, data, series, curse),
          series
        )
      });
    });
  }
  else if (anyPattern === 2) {
    anyDevilList.forEach((data) => {
      resultDevilSet.push({
        devil1: devil1,
        devil2: convertRaceName(data, series),
        resultDevil: convertRaceName(
          getResultForSpirit(
            convertRaceName(data, series),
            devil1,
            series,
            curse
          ),
          series
        )
      });
    });
  }
  else if (anyPattern === 3) {
    anyDevilList.forEach((data) => {
      resultDevilSet.push({
        devil1: convertRaceName(data, series),
        devil2: devil2,
        resultDevil: convertRaceName(
          getResultForSpirit(
            convertRaceName(data, series),
            devil2,
            series,
            curse
          ),
          series
        )
      });
    });
  }
  else if (anyPattern === 4) {
    anyDevilList.forEach((data) => {
      resultDevilSet.push({
        devil1: devil1,
        devil2: convertRaceName(data, series),
        resultDevil: convertRaceName(
          getResultForSpirit(devil1, data, series, curse),
          series
        )
      });
    });
  }
  else if (anyPattern === 5) {
    resultDevilSet = [{
      devil1: convertRaceName(devil1, series),
      devil2: convertRaceName(devil2, series),
      resultDevil: convertRaceName(
        getResultForSpirit(devil2, devil1, series, curse),
        series
      ),
    }];
  }
  else if (anyPattern === 6) {
    resultDevilSet = [{
      devil1: convertRaceName(devil1, series),
      devil2: convertRaceName(devil2, series),
      resultDevil: convertRaceName(
        getResultForSpirit(devil1, devil2, series, curse),
        series
      ),
    }];
  }

  return resultDevilSet;
};

export default SpiritFusion;
