import React from 'react';
import './TopPage.scss';
import {
  useDispatch,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ActionType } from '../../ducks/actions';
import Loading from '../../components/Loading/Loading';
import { getBasePath } from '../../common/PathManager';

function TopPage() {

  const dispatch = useDispatch();

  const history = useHistory();
  const handleOnClickDevilList3 = () => {
    seriesChangeSetting('m_3');
    history.push(getBasePath() + 'm-3/devil-list');
  };
  const handleOnClickStandardFusion3 = () => {
    seriesChangeSetting('m_3');
    history.push(getBasePath() + 'm-3/devil-fusion');
  };
  const handleOnClickSpecialFusion3 = () => {
    seriesChangeSetting('m_3');
    history.push(getBasePath() + 'm-3/special-fusion');
  };

  const handleOnClickDevilListDsj = () => {
    seriesChangeSetting('m_dsj');
    history.push(getBasePath() + 'm-dsj/devil-list');
  };
  const handleOnClickStandardFusionDsj = () => {
    seriesChangeSetting('m_dsj');
    history.push(getBasePath() + 'm-dsj/devil-fusion');
  };
  const handleOnClickSpecialFusionDsj = () => {
    seriesChangeSetting('m_dsj');
    history.push(getBasePath() + 'm-dsj/special-fusion');
  };

  const seriesChangeSetting = (series: string) => {
    devilListReset(series);
    resultClear(series);
    choiceClear(series);
  }

  const devilListReset = (series: string) => {
    dispatch({ type: ActionType.DEVIL_LIST_RESET, series: series })
  }

  const resultClear = (series: string) => {
    dispatch({ type: ActionType.RESULT_CLEAR, series: series })
  }

  const choiceClear = (series: string) => {
    dispatch({ type: ActionType.CHOICE_CLEAR, series: series })
  }

  // const [loadingFlg, setLoadingFlg] = useState(true);
  // setTimeout(() => {
  //   setLoadingFlg(false);
  // }, 2000);

  return (
    <div className="toppage-wrapper">
      <div className="toppage-contents">
        <div className="series megaten3">
          <div className="title">真・女神転生3</div>
          <div className="button-wrapper">
            <div className="top-button" onClick={handleOnClickDevilList3}>
              <div className="button-name">悪魔一覧</div>
            </div>
            <div className="top-button" onClick={handleOnClickStandardFusion3}>
              <div className="button-name">二身合体</div>
            </div>
            <div className="top-button" onClick={handleOnClickSpecialFusion3}>
              <div className="button-name">特殊合体</div>
            </div>
          </div>
        </div>

        <div className="series megaten-dsj">
          <div className="title">真・女神転生 DEEP STRANGE JOURNEY</div>
          <div className="button-wrapper">
            <div className="top-button" onClick={handleOnClickDevilListDsj}>
              <div className="button-name">悪魔一覧</div>
            </div>
            <div className="top-button" onClick={handleOnClickStandardFusionDsj}>
              <div className="button-name">二身合体</div>
            </div>
            <div className="top-button" onClick={handleOnClickSpecialFusionDsj}>
              <div className="button-name">特殊合体</div>
            </div>
          </div>
        </div>
        <div className="push"></div>
      </div>

      <small className="copyright">
        ©ATLUS ©SEGA <br />
        株式会社アトラスに関わる画像の著作権、その他一切の知的財産権は、全て株式会社アトラスに帰属します。
      </small>
    </div>
  );
}

export default TopPage;

