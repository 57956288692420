import React from 'react';
import './Header.scss';
import PageTop from '../PageTop/PageTop';
import TopPage from '../TopPage/TopPage';
import { Sort } from '../Sort/Sort';
import { LoggingManager as logger } from '../../common/CommonLogger';

function Header(props) {

  logger(['Header', props.title]);

  return (
    <div className="header">
      <div className="left-button">
        <div className={"header-button toppage " + (props.toppage ? 'hidden' : '')}>
          <TopPage></TopPage>
        </div>
        <div className={"header-button sort " + (props.sort ? 'hidden' : '')}>
          <Sort></Sort>
        </div>
      </div>
      <div className="title">
        {props.title}
      </div>
      <div className="header-button pagetop">
        <PageTop></PageTop>
      </div>
    </div>
  );

}

export default Header;