import React from 'react';
import './PageTop.scss';

function PageTop() {

  const pageTopIcon = require('../../assets/img/arrow_icon.png');
  const pageTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className="page-top" onClick={pageTop}>
      <img src={pageTopIcon} alt="page-top"/>
    </div>
  );

}

export default PageTop;